import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

const ApplyNOC = () => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();

  const showHandler = (type) => {
    setModalData("https://www.africau.edu/images/default/sample.pdf");

    setModal(true);
  };
  return (
    <div className="bg-light my-2">
      <div className="generallinformation">
        <div className="block block-rounded p-2">
          <div className="block-content block-content-full">
            <div className="row">
              <Col lg={12}>
                <div className="blocks block-rounded tables">
                  <table className="tablelist table-borderless user-det">
                    <thead>
                      <th
                        colspan="3"
                        className="h5 text-light mb-0 font-weight-light p-3"
                      >
                        APPLY NOCS
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Would you like to apply for Fire Service NOC with this
                          application ?
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Would you like to apply for Environmental Clearence
                          Service NOC with this application ?
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Would you like to apply for Traffic Service NOC with
                          this application ?
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Would you like to apply for AAI Service NOC with this
                          application ?
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Would you like to apply for AAI Service NOC with this
                          application ?
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="w-100 justify-content-center">
            <iframe
              src={modalData}
              style={{ height: "500px" }}
              className="w-100"
            ></iframe>
            {/* <div className="d-flex w-50">
              <img
                src={modalData}
                className="w-100 text-center mt-2 mb-2"
              ></img>
            </div> */}
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ApplyNOC;
