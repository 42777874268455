import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserPasswordChangeMutation } from "../../../../redux/api/api";
import Topbar from "../../Topbar";

const UserPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const types = location?.state?.type;
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [userPasswordChangeApi] = useUserPasswordChangeMutation();

  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [cPasswordErr, setCPasswordErr] = useState(false);

  const submitHandler = () => {
    if (password?.length < 8 || cPassword !== password) {
      setPasswordErr(true);
      setCPasswordErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("user_id", location?.state?.data?.id);
      formdata.append("password", password);
      formdata.append("password_confirmation", cPassword);
      setBtn(true);
      userPasswordChangeApi(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          if (res?.status == "success") {
            alert(res?.message);
            navigate("/users");
          }
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  return (
    <div>
      <Topbar type={"user-password"} />
      <div className="content">
        <div className="block bg-light block-rounded">
          <div className="block-content user-frm">
            <Row>
              <Col md={6}>
                <div className="form-group position-relative">
                  <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                    Password *
                  </label>
                  <input
                    className="add-admin-frm fs-xs-12 fs-sm-13 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-17"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  {password?.length < 8 && passwordErr && (
                    <p className="err">Enter Password</p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group position-relative">
                  <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                    Confirm Password *
                  </label>
                  <input
                    className="add-admin-frm fs-xs-12 fs-sm-13 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-17"
                    onChange={(e) => setCPassword(e.target.value)}
                    value={cPassword}
                  />
                  {cPassword?.length == 0 && cPasswordErr && (
                    <p className="err">Enter Confirm Password</p>
                  )}
                  {cPassword?.length > 0 && cPassword !== password && (
                    <p className="err">
                      Password Confirm Password Does not match.
                    </p>
                  )}
                </div>
              </Col>

              <div className="form-btn mb-4">
                <button onClick={submitHandler} disabled={btn ? true : false}>
                  Submit
                </button>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPassword;
