import React from "react";
import { Col } from "react-bootstrap";
import { Logo } from "../../../assets";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";

const StaffLogin = () => {
  const navigate = useNavigate();
  return (
    <div className="main_container">
      <div class="hero-static bg-light">
        <div class="content pt-3">
          <div className="row justify-content-center mt-5">
            <Col md={12} lg={12} xl={6} className="mt-5 p-0">
              <div className="mb-0 mt-5 p-0 bg-form rounded shadow">
                <div className="card_login">
                  <div className="col-lg-6 col-sm-12">
                    <div className="py-5 px-2">
                      <div className="text-center">
                        <img
                          src={Logo}
                          className="img-fluid text-center my-1 bg-white rounded p-1"
                          alt=""
                        />
                      </div>

                      <h3 className="login_h5 mt-4 text-white text-uppercase text-center">
                        Autometry Building Plan Approval System
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="p-sm-3 px-lg-4 py-lg-3 bg-white">
                      <h4 className="text-dark font-weight-bolder my-2">
                        Welcome Officers, please login.
                      </h4>

                      <div>
                        <div className="py-3">
                          <div className="form-groups">
                            <input
                              className="form-control-alts form-controls form-control-lgs"
                              id="username"
                              name="username"
                              placeholder="Username"
                            ></input>
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control-alts form-controls form-control-lgs"
                              id="password"
                              name="password"
                              placeholder="Password"
                            ></input>
                          </div>
                          {/* <div style="color: red">
                       {{ loginErrors }}
                      </div> */}
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                          <div className="row form-group text-center">
                            <Col className="mt-4">
                              <button
                                className="btn btn-alt-light btn-block d-flex gap-2"
                                onClick={() => navigate("/staff/dashboard")}
                              >
                                <LoginIcon />
                                Sign In
                              </button>
                            </Col>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffLogin;
