import logo from "./logo.svg";
import "./App.css";
import ReactRoutes from "./routes";
import { Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function App() {
  const [loading, setLoading] = useState(false);

  // For Loader
  const isSomeQueryPending = useSelector((state) =>
    Object.values(state.api.queries).some((query) => query.status === "pending")
  );

  useEffect(() => {
    if (isSomeQueryPending) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [isSomeQueryPending]);

  return (
    <div>
      {loading && (
        <div
          style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}
        >
          <Spinner animation="border" variant="primary" />
          <span style={{ marginLeft: 10 }}>Loading...</span>
        </div>
      )}
      <ReactRoutes />
    </div>
  );
}

export default App;
