import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GeneralInformation from "./applications/GeneralInformation";
import ApplicantInformation from "./applications/ApplicantInformation";
import ApplicantCheckList from "./applications/ApplicantCheckList";
import PlotDetails from "./applications/PlotDetails";
import BuildingDetails from "./applications/BuildingDetails";
import SiteDetails from "./applications/SiteDetails";
import ApplyNOC from "./applications/ApplyNOC";
import Document from "./applications/Document";
import Drawing from "./applications/Drawing";
import IntimationFeeLetter from "./applications/IntimationFeeLetter";

const ApplicationList = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="bg-body-light">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
            <h1 className="flex-sm-fill app_h3 my-2">Applications</h1>
            <div className="flex-sm-00-auto ml-sm-3">
              <div className="d-flex navi-btn">
                <button className="" onClick={() => navigate(-1)}>
                  Dashboard
                </button>
                <NavigateNextIcon />
                <button className="active">Home</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card bg-white rounded mx-2 my-2 px-2 py-2 shadow">
        <Tabs id="uncontrolled-tab-example" className="mb-3 in-user-app gap-1">
          <Tab
            eventKey="generalinformation"
            title="General Information"
            className="py-0"
          >
            <GeneralInformation />
          </Tab>
          <Tab
            eventKey="applicationinformation"
            title="Applicant Information"
            className=" py-0"
          >
            <ApplicantInformation />
          </Tab>
          <Tab
            eventKey="applicationchecklist"
            title="Applicant CheckList"
            className="py-0"
          >
            <ApplicantCheckList />
          </Tab>
          <Tab eventKey="plotdetails" title="Plot Details" className=" py-0">
            <PlotDetails />
          </Tab>
          <Tab
            eventKey="buildingdetails"
            title="Building Details"
            className=" py-0"
          >
            <BuildingDetails />
          </Tab>
          <Tab eventKey="sitedetails" title="Site Details" className=" py-0">
            <SiteDetails />
          </Tab>
          <Tab eventKey="applynoc" title="Apply NOC" className=" py-0">
            <ApplyNOC />
          </Tab>
          <Tab eventKey="document" title="Document" className=" py-0">
            <Document />
          </Tab>
          <Tab eventKey="drawing" title="Drawing" className=" py-0">
            <Drawing />
          </Tab>
          <Tab
            eventKey="inimation_fee_letter"
            title="Intimation Fee Letter"
            className=" py-0"
          >
            <IntimationFeeLetter />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default ApplicationList;
