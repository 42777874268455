import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../NavBar/NavBar";

const Layout = () => {
  return (
    <div className="bg-lightBlue d-md-flex min-h-100">
      <div className="top-design position-fixed">
        <NavBar />
      </div>
      <div
        className="body-design w-100"
        style={{
          marginLeft: "240px",
          transition: "0.2s all linear",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
