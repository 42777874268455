import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../Table";
import Topbar from "../../Topbar";
import EditIcon from "@mui/icons-material/Edit";
import {
  useLazyApplicationDocumentListQuery,
  useLazyApplicationDocumentStatusQuery,
} from "../../../../redux/api/api";

const ApplicationDocuments = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [btn, setBtn] = useState(false);
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "NAME",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "TYPE",
        accessor: "type",
        Cell: ({ value, row }) => {
          return (
            <p>
              {value == 1
                ? "Primary"
                : value == 2
                ? "Additional"
                : value == 3
                ? "MSB Hr"
                : ""}
            </p>
          );
        },
      },
      {
        Header: "STATUS",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              className={
                value == 0
                  ? "btn btn-success btn-sm btn-danger"
                  : "btn btn-success btn-sm"
              }
              onClick={() => statusChange(row.original.id)}
              disabled={btn ? true : false}
            >
              {value == 0 ? "Deactive" : "Active"}
            </button>
          );
        },
      },

      {
        Header: "ACTION",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <button
                className="comn-btn  edit-btn-svg bg-transparent border-0 text-primary"
                onClick={() =>
                  navigate("/application-document", {
                    state: { data: row.original, type: "edit" },
                  })
                }
              >
                <EditIcon />
              </button>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  // RTK QUERY
  const [applicationDocListApi] = useLazyApplicationDocumentListQuery();
  const [applicationDocStatusApi] = useLazyApplicationDocumentStatusQuery();

  const getList = () => {
    applicationDocListApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.application_document);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const statusChange = (id) => {
    setBtn(true);
    applicationDocStatusApi(id)
      .unwrap()
      .then((res) => {
        setBtn(false);
        if (res?.status == "success") {
          getList();
        }
      })
      .catch((err) => {
        setBtn(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div>
      <Topbar type={"application-document"} />
      <div className="p-3">
        <div className="block bg-light block-rounded">
          <div className="block-content">
            <Table
              columns={columns}
              data={list}
              type={"no-search"}
              path={"/application-document"}
              className=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationDocuments;
