import React, { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { GlobalFilter } from "../../../staff/components/dashboards/GlobalFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Topbar from "../../Topbar";
import {
  useLazyQueueListQuery,
  useLazyRolesQuery,
  useLazyRoleStatusQuery,
  useQueueClearMutation,
} from "../../../../redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="text-center  mb-2 py-3"
            >
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="text-center  mb-2 py-3"
                  style={{ fontFamily: "f1", fontSize: "14px" }}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="text-center  py-2">
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="text-center py-2">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const QueueList = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  // RTK QUERY
  const [queueListApi] = useLazyQueueListQuery();
  const [queueClearApi] = useQueueClearMutation();

  const getList = () => {
    queueListApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.queued_applications);
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const clearQueue = (type) => {
    let formdata = new FormData();
    formdata.append("clear_type", type);
    queueClearApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          alert(res?.message);
          getList();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "APPLICATION TYPE",
        accessor: "user_application_drawing.user_application.application_type",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "FILE NO",
        accessor: "user_application_drawing.user_application.file_no",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "DRAWING ID",
        // accessor: "drawing_id",
        accessor: "user_application_drawing_id",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "REQUEST SENT TIME",
        accessor: "request_sent_time",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "STATUS",
        accessor: "extraction_status",
        Cell: ({ value, row }) => {
          return (
            <button className={"btn btn-success btn-sm"}>
              {value == 1
                ? "In Queue"
                : value == 2
                ? "Precheck Request Sent"
                : "Extraction Request Sent"}
            </button>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <Topbar type={"queues"} />
      <div className="p-3">
        <div className="block bg-light block-rounded">
          <div className="block-content">
            <div className="d-flex justify-content-end">
              <Button
                style={{ background: "#727b84" }}
                className="btn-sm btn-round has-ripple ml-2 border-0 text-white"
                onClick={() => clearQueue("current")}
              >
                Current Clear
              </Button>
            </div>
            <div className="d-flex justify-content-end mt-3 mb-5">
              <Button
                style={{ background: "#727b84" }}
                className="btn-sm btn-round has-ripple ml-2 border-0 text-white"
                onClick={() => clearQueue("next_file")}
              >
                Next File Clear
              </Button>
            </div>
            <Table columns={columns} data={list} className="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueueList;
