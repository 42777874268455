import React, { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { GlobalFilter } from "../../../staff/components/dashboards/GlobalFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Topbar from "../../Topbar";
import {
  useLazyRolesQuery,
  useLazyRoleStatusQuery,
} from "../../../../redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show">
        <Col className="d-flex justify-content-end" md={12} lg={12} sm={12}>
          <Link to="/role-add">
            <Button
              style={{ background: "rgb(95,188,95)" }}
              className="btn-sm btn-round has-ripple ml-2 border-0 text-white"
            >
              <i className="feather icon-plus" /> + Add Role
            </Button>
          </Link>
        </Col>
        <Col className="d-flex justify-content-start">
          <div>
            <label className="font-600 mb-2">Search</label>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </div>
        </Col>
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="text-center  mb-2 py-3"
            >
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="text-center  mb-2 py-3"
                  style={{ fontFamily: "f1", fontSize: "14px" }}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="text-center  py-2">
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="text-center py-2">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

function Roles() {
  const navigate = useNavigate();

  // RTK QUERY
  const [rolesApi] = useLazyRolesQuery();
  const [rolesStatusApi] = useLazyRoleStatusQuery();
  const [list, setList] = useState([]);
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "ROLE",
        accessor: "role",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "STATUS",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              className={
                value == 0
                  ? "btn btn-success btn-sm btn-danger"
                  : "btn btn-success btn-sm"
              }
              onClick={() => statusChange(row.original.id)}
            >
              {value == 0 ? "Deactive" : "Active"}
            </button>
          );
        },
      },

      {
        Header: "ACTION",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <button
                className="comn-btn  edit-btn-svg bg-transparent border-0 text-primary"
                onClick={() =>
                  navigate("/role-add", {
                    state: { data: row.original, type: "edit" },
                  })
                }
              >
                <EditIcon />
              </button>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const getList = () => {
    rolesApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.roles);
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const statusChange = (id) => {
    rolesStatusApi(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          alert(res?.message);
          getList();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div>
      <Topbar type={"roles"} />
      <div className="p-3">
        <div className="block bg-light block-rounded">
          <div className="block-content">
            <Table columns={columns} data={list} className="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roles;
