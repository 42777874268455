import React, { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { GlobalFilter } from "./GlobalFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show">
        {/* <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col> */}
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="text-center border-0 border-bottom-0  mb-2 py-3"
            >
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="text-center border-0 border-bottom-0  mb-2 py-3"
                  style={{ fontFamily: "f1", fontSize: "14px" }}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="text-center border-0 border-bottom-0   py-3"
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="text-center border-0 border-bottom-0  py-3"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        {/* <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col> */}
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const NineFiles = ({ type }) => {
  const [list, setList] = useState([]);

  console.log("nine_type", type);
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "APPLICATION NUMBER",
        accessor: "id",
        Cell: ({ value, row }) => {
          return <p>{"#AD" + value}</p>;
        },
      },
      {
        Header: "APPLICATION TYPE",
        accessor: "application_type",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "CASE TYPE",
        accessor: "case_type",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "APPLICANT NAME",
        accessor: "applicant_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "TYPE",
        accessor: "type",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "IALA",
        accessor: "iala",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },

      {
        Header: "VIEW",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <button className="comn-btn  edit-btn-svg bg-transparent border-0 text-primary">
                <VisibilityIcon />
              </button>
            </React.Fragment>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "ISSUE",
        accessor: "issue",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
    ],
    []
  );

  useEffect(() => {
    let temp = [
      {
        id: "IIC/187/2022",
        application_type: "Commercial",
        case_type: "Revision",
        applicant_name: "Vijay",
        type: "Architect",
        iala: "Adilabad",
        status: "Staff Processing",
      },
      {
        id: "IIC/84/2023",
        application_type: "Commercial",
        case_type: "Revision",
        applicant_name: "Lee",
        type: "Architect",
        iala: "Adilabad",
        status: "Completed",
      },
    ];

    setList(temp);
  }, []);

  return (
    <div className="block bg-light block-rounded">
      <div className="block-content">
        <Table columns={columns} data={list} className="" />
      </div>
    </div>
  );
};

export default NineFiles;
