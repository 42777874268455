// @ts-nocheck
import React, { useState } from "react";
import { Modal, Button, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import Login from "../screens/Login";
import NavBarTop from "./NavBarTop";
import { Avatar, Brand, Logo } from "../../../assets";
import PersonIcon from "@mui/icons-material/Person";
import useUser from "../../../redux/store/userData";
import { useLazyLogoutQuery } from "../../../redux/api/api";

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [hider, setHider] = useState(true);
  const permissions = localStorage.getItem("permissions");
  const [actionBtn, setActionBtn] = useState(false);
  const [bB, setBB] = useState(false);
  const [hrM, setHrM] = useState(true);
  const [procurment, setProcurment] = useState(false);
  const [execution, setExecution] = useState(false);
  const [ism, setIsm] = useState(false);
  const [fn, setFn] = useState(false);
  const [report, setReport] = useState(false);
  const [logout, setLogout] = useState(false);
  const [show, setShow] = useState(false);
  const [changePassword, setChangePassword] = useState("");
  const [passConfirm, setPassConfirm] = useState("");

  const [logoutApi] = useLazyLogoutQuery();

  const logOutAcc = () => {
    let confirm = window.confirm("Do you want to Logout?");
    if (confirm) {
      logoutApi()
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            localStorage.clear();
            alert("Logut Successfully");
            setTimeout(() => {
              window.location.href = "/";
            }, 300);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <>
      <div className="nav-bar">
        <NavBarTop />
        <div className="menu-list">
          <div className="menu-list_main">
            <h5 className="nav-main-heading">ADMIN & ROLE</h5>
            <div className="nav-main-item" onClick={() => navigate("/admin")}>
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "admin"
                    ? "nav-main-link-active"
                    : splitLocation[1] === "admin-add"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Admin</p>
              </div>
            </div>
            <div className="nav-main-item" onClick={() => navigate("/roles")}>
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "roles"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Role</p>
              </div>
            </div>
          </div>
          <div className="menu-list_main">
            <h5 className="nav-main-heading">USERS</h5>
            <div className="nav-main-item" onClick={() => navigate("/users")}>
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "users"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">User</p>
              </div>
            </div>
            <div
              className="nav-main-item"
              onClick={() => navigate("/users-application")}
            >
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "users-application"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">User Applications</p>
              </div>
            </div>
          </div>
          {/* <div className="menu-list_main">
            <h5 className="nav-main-heading">STAFFS</h5>
            <div
              className="nav-main-item"
              // onClick={() => navigate("/users")}
            >
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "users6"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Staff</p>
              </div>
            </div>
          </div> */}
          <div className="menu-list_main">
            <h5 className="nav-main-heading">MASTER</h5>
            <div className="nav-main-item" onClick={() => navigate("/places")}>
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "places"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Place</p>
              </div>
            </div>
            {/* <div
              className="nav-main-item"
              onClick={() => navigate("/building-details")}
            >
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "building-details"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Building Details</p>
              </div>
            </div> */}
            <div
              className="nav-main-item"
              onClick={() => navigate("/site-details-question-list")}
            >
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "site-details-question-list"
                    ? "nav-main-link-active"
                    : splitLocation[1] === "site-details-question"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Site Details Question</p>
              </div>
            </div>
            <div
              className="nav-main-item"
              onClick={() => navigate("/application-document-list")}
            >
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "application-document-list"
                    ? "nav-main-link-active"
                    : splitLocation[1] === "application-document"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Application Document</p>
              </div>
            </div>
            <div
              className="nav-main-item"
              onClick={() => navigate("/application-checklist-list")}
            >
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "application-checklist-list"
                    ? "nav-main-link-active"
                    : splitLocation[1] === "application-checklist"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Application CheckList</p>
              </div>
            </div>
            <div
              className="nav-main-item"
              onClick={() => navigate("/proposed-activity-list")}
            >
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "proposed-activity-list"
                    ? "nav-main-link-active"
                    : splitLocation[1] === "proposed-activity"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Proposed Activity</p>
              </div>
            </div>
            {/* <div
              className="nav-main-item"
              // onClick={() => navigate("/users")}
            >
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "users1"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Plot Location</p>
              </div>
            </div> */}
            {/* <div
              className="nav-main-item"
              // onClick={() => navigate("/users")}
            >
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "users2"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Charges</p>
              </div>
            </div> */}
            <div
              className="nav-main-item"
              onClick={() => navigate("/queue-list")}
            >
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "queue-list"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Queue List</p>
              </div>
            </div>
            {/* <div
              className="nav-main-item"
              // onClick={() => navigate("/users")}
            >
              <div
                className="nav-main-link"
                className={
                  splitLocation[1] === "users4"
                    ? "nav-main-link-active"
                    : "nav-main-link"
                }
              >
                <PersonIcon className="nav-main-link-icon mb-1" />
                <p className="nav-main-link-name">Pdf Check</p>
              </div>
            </div>  */}
          </div>
        </div>

        <Modal centered show={show} onHide={() => setShow(!show)}>
          <Modal.Header className="border-0" closeButton>
            <label className="w-100 text-center">Change Password</label>
          </Modal.Header>
          <Modal.Body>
            <Col xs={10} className="inputinner-box text-start mx-auto">
              <label className="w-100">Enter Password</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Password"
                value={changePassword}
                onChange={(e) => setChangePassword(e.target.value)}
              />
            </Col>
            <Col xs={10} className="inputinner-box text-start mx-auto">
              <label className="w-100">Enter Confirmation Password</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Confirmation Password"
                value={passConfirm}
                onChange={(e) => setPassConfirm(e.target.value)}
              />
            </Col>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button variant="secondary" onClick={() => setShow(!show)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => console.log("ss")}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="page-header" style={{ position: "fixed" }}>
        <div className="content-header" style={{ height: "4rem" }}>
          <div className="d-flex align-items-center"></div>
          <div className="d-flex align-items-center gap-2">
            <p className="text-center text-capitalize">{user?.name}</p>
            <div>
              <button
                onClick={() => setActionBtn(!actionBtn)}
                className="position-relative  drop-btn drop-btns  ms-1 text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-2 rounded"
              >
                <img src={Avatar} className="mx-1" />
                TSIIC
                <ArrowDropDownIcon className="mx-1" />
              </button>
              {actionBtn && (
                <div className="action_btn ml-1">
                  <button
                    className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                    onClick={() => logOutAcc()}
                  >
                    <div className="logout">
                      <p className="mt-2"> Admin Logout</p>
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
