import React, { useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import Topbar from "../../Topbar";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { FaEdit } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoClose } from "react-icons/io5";

const BuildingDetails = () => {
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [modelDisplay, setModelDisplay] = useState("");
  const [subModel, setSubModel] = useState([]);
  const [activeBtn, setActiveBtn] = useState(null);

  const handleClose = (type) => {
    setShow(!show);
    setModelDisplay(type);
  };

  const handleCloses = (type, data) => {
    setShows(!shows);
    setModelDisplay(type);
  };

  const subModelHandler = (num, ind) => {
    let temp = [...subModel];
    let inx = temp.indexOf(num);
    if (temp.includes(num)) {
      temp.splice(inx, temp.length);
      temp.push(num);
    } else {
      temp.push(num);
    }
    setSubModel(temp);
    setActiveBtn(ind);
  };

  //   console.log("subModel", subModel);

  return (
    <div>
      <Topbar type={"building-details"} />
      <div className="p-3">
        <div className="block bg-light block-rounded">
          <div className="block-content">
            <div className="row d-flex flex-wrap p-3 mb-3">
              <Col md={6} sm={6} lg={6} xs={12} className="mb-3">
                <div className="w-100">
                  <div className="w-100">
                    <div class="h5 text-primary font-800 mb-1">Use</div>
                    <button
                      className="mb-1 mt-2 border-0 w-100 place-btn"
                      onClick={() => handleClose("Use")}
                    >
                      + Add Use
                    </button>
                  </div>
                  <div className="mb-2 w-100">
                    {["Residential", "Commercial", "Industrial"].map(
                      (item, ind) => {
                        return (
                          <div
                            className={`w-100 ${
                              activeBtn == ind
                                ? "place-list-active"
                                : " place-list"
                            } d-flex justify-content-between align-items-center`}
                          >
                            <p className="font-500">{item}</p>
                            <div className="d-flex place-flot align-items-center">
                              <button
                                className="place-icon p-2"
                                onClick={() => handleCloses("Use", item)}
                              >
                                <FaEdit className="d-flex align-items-center justify-content-center mx-1" />
                              </button>
                              <button
                                className="place-icon p-2"
                                onClick={() => subModelHandler(1, ind)}
                              >
                                <MdKeyboardArrowRight className="d-flex align-items-center justify-content-center mx-1" />
                              </button>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>
              {subModel?.includes(1) && (
                <Col md={6} sm={6} lg={6} xs={12} className="mb-3">
                  <div className="w-100">
                    <div class="h5 text-primary font-800 mb-1">Sub Use</div>
                    <button
                      className="mb-1 mt-2 border-0 w-100 place-btn"
                      onClick={() => handleClose("Sub Use")}
                    >
                      + Add Sub Use
                    </button>
                  </div>
                  <div className="mb-2 w-100">
                    {["Shop", "Store", "Retail Shop"].map((item, ind) => {
                      return (
                        <div className="w-100 place-list d-flex justify-content-between align-items-center">
                          <p className="font-500">{item}</p>
                          <div className="d-flex place-flot align-items-center">
                            <button
                              className="place-icon p-2"
                              onClick={() => handleCloses("Sub Use", item)}
                            >
                              <FaEdit className="d-flex align-items-center justify-content-center mx-1" />
                            </button>
                            <button
                              className="place-icon p-2"
                              onClick={() => subModelHandler(2, null)}
                            >
                              <MdKeyboardArrowRight className="d-flex align-items-center justify-content-center mx-1" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              )}
              {subModel?.includes(2) && (
                <Col md={6} sm={6} lg={6} xs={12} className="mb-3">
                  <div className="w-100">
                    <div class="h5 text-primary font-800 mb-1">Type</div>
                    <button
                      className="mb-1 mt-2 border-0 w-100 place-btn"
                      onClick={() => handleClose("Type")}
                    >
                      + Add Type
                    </button>
                  </div>
                  <div className="mb-2 w-100">
                    {[
                      "Row House",
                      "Single Detached House",
                      "Single Semi Detached House",
                    ].map((item, ind) => {
                      return (
                        <div className="w-100 place-list d-flex justify-content-between align-items-center">
                          <p className="font-500">{item}</p>
                          <div className="d-flex place-flot align-items-center">
                            <button
                              className="place-icon p-2"
                              onClick={() => handleCloses("Type", item)}
                            >
                              <FaEdit className="d-flex align-items-center justify-content-center mx-1" />
                            </button>
                            {/* <button
                                className="place-icon p-2"
                                onClick={() => subModelHandler(3, ind)}
                              >
                                <MdKeyboardArrowRight className="d-flex align-items-center justify-content-center mx-1" />
                              </button> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <div className="d-flex w-100 justify-content-between p-4">
          <p className="font-900" style={{ fontSize: "18px" }}>
            {"Add " + modelDisplay}
          </p>
          <button
            className="border-0 bg-transparent"
            onClick={() => handleClose("")}
          >
            <IoClose className="font-900" style={{ fontSize: "20px" }} />
          </button>
        </div>
        <div className="p-3 mb-3 mx-2 w-90">
          <label className="w-100">{modelDisplay} Name</label>
          <input className="w-100 p-1  form-control mb-3" />
          <div className="d-flex justify-content-end gap-2">
            <button
              onClick={() => handleClose("")}
              className="btn btn-success btn-sm btn-danger"
            >
              Cancel
            </button>
            <button
              onClick={() => handleClose("")}
              className="btn btn-success btn-sm"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <Modal show={shows} onHide={handleCloses}>
        <div className="d-flex w-100 justify-content-between p-4">
          <p className="font-900" style={{ fontSize: "18px" }}>
            {"Edit " + modelDisplay}
          </p>
          <button
            className="border-0 bg-transparent"
            onClick={() => handleCloses("")}
          >
            <IoClose className="font-900" style={{ fontSize: "20px" }} />
          </button>
        </div>
        <div className="p-3 mb-3 mx-2 w-90">
          <label className="w-100">{modelDisplay} Name</label>
          <input className="w-100 p-1  form-control mb-3" />
          <div className="d-flex justify-content-end gap-2">
            <button
              onClick={() => handleCloses("")}
              className="btn btn-success btn-sm btn-danger"
            >
              Cancel
            </button>
            <button
              onClick={() => handleCloses("")}
              className="btn btn-success btn-sm"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BuildingDetails;
