import React from "react";
import { Col } from "react-bootstrap";

const GeneralInformation = () => {
  return (
    <div className="bg-light my-2">
      <div className="generallinformation">
        <div className="block block-rounded p-2">
          <div className="block-content block-content-full">
            <div className="row">
              <Col lg={12}>
                <div className="blocks block-rounded tables">
                  <table className="tablelist table-borderless user-det">
                    <thead>
                      <th
                        colspan="3"
                        className="h5 text-light mb-0 font-weight-light p-3"
                      >
                        Personal Information
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>Project Type</td>
                        <td>:</td>
                        <td>Buiding Permission</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Application Type</td>
                        <td>:</td>
                        <td>Commercial</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Case Type</td>
                        <td>:</td>
                        <td>Revision</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Zone</td>
                        <td>:</td>
                        <td>Nizamabad</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>District</td>
                        <td>:</td>
                        <td>Adilabad</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>IALA</td>
                        <td>:</td>
                        <td>Adilabad</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Industrial Park</td>
                        <td>:</td>
                        <td>IP Adilabad</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Mandal</td>
                        <td>:</td>
                        <td> Adilabad (Mavala-New)</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Village</td>
                        <td>:</td>
                        <td>Adilabad (Mavala-New)</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Fire NOC is obtained for a Refuge Area Non Compliance
                        </td>
                        <td>:</td>
                        <td>no</td>
                      </tr>
                      <tr class="bg-purple p-2 rounded my-2">
                        <td
                          colspan="3"
                          class="h5_2 text-light mb-0 font-weight-light p-3"
                        >
                          Site Details
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Site Number</td>
                        <td>:</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Site Number Type</td>
                        <td>:</td>
                        <td>NA</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Road/Street</td>
                        <td>:</td>
                        <td>2</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInformation;
