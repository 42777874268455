import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, TOKEN, URL } from "./constants";
import { getToken } from "../store/userTocken";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      // const token = getToken();
      const token = localStorage.getItem(TOKEN);
      headers.set("Authorization", "Bearer " + token);
      // headers.set("Content-Type", "multipart/form-data");
      // headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [],
  endpoints: (builder) => ({
    // LOGIN
    login: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN,
        body: payload,
        method: "POST",
      }),
    }),

    // LOGOUT
    logout: builder.query({
      query: () => URL.LOGOUT,
    }),

    // CHANGE PASSWORD
    changePassword: builder.mutation({
      query: (payload) => ({
        url: URL.CHANGE_PASSWORD,
        body: payload,
        method: "POST",
      }),
    }),

    // SET PASSWORD
    changePassword: builder.mutation({
      query: (payload) => ({
        url: URL.SET_PASSWORD,
        body: payload,
        method: "POST",
      }),
    }),

    // ROLE
    roleCreate: builder.query({
      query: () => URL.ROLE + "/create",
    }),

    // ROLE ADD
    roleStore: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE,
        body: payload,
        method: "POST",
      }),
    }),

    // ROLE EDIT
    roleEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ROLE + "/" + id,
        body: payload,
        method: "POST",
      }),
    }),

    // ROLE VIEW
    roleView: builder.query({
      query: (id) => URL.ROLE + "/" + id,
    }),

    // ROLE ALL
    roles: builder.query({
      query: () => URL.ROLE,
    }),

    // ROLE STATUS
    roleStatus: builder.query({
      query: (id) => URL.ROLE + "/status/" + id,
    }),

    // ACTIVE ROLE
    activeRoles: builder.query({
      query: (id) => URL.ACTIVE_ROLES,
    }),

    // ADMIN ADD
    adminAdd: builder.mutation({
      query: (payload) => ({
        url: URL.ADMIN,
        body: payload,
        method: "POST",
      }),
    }),

    // ADMIN EDIT
    adminEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ADMIN + "/" + id,
        body: payload,
        method: "POST",
      }),
    }),

    // ADMIN VIEW
    adminView: builder.query({
      query: (id) => URL.ADMIN + "/" + id,
    }),

    // ADMIN ALL
    Admins: builder.query({
      query: () => URL.ADMIN,
    }),

    // ADMIN STATUS
    adminStatus: builder.query({
      query: (id) => URL.ADMIN + "/status/" + id,
    }),

    // PLACE ADD
    placeAdd: builder.mutation({
      query: (payload) => ({
        url: URL.PLACE_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    // PLACE EDIT
    palceEdit: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.PLACE_UPDATE + id,
        body: payload,
        method: "POST",
      }),
    }),

    // PLACE VIEW
    palceView: builder.query({
      query: (id) => URL.PLACE_EDIT + id,
    }),

    // PLACE ALL
    places: builder.query({
      query: () => URL.PLACE_LIST,
    }),

    // PLACE STATUS
    placeParentList: builder.query({
      query: (id) => URL.PLACE_PARENT_lIST + id,
    }),

    // APPLICATION CHECK LIST
    applicationCheckList: builder.query({
      query: () => URL.APPLICATION_CHECK_LIST,
    }),

    // APPLICATION CHECK LIST
    applicationCheckView: builder.query({
      query: (id) => URL.APPLICATION_CHECK_VIEW + id,
    }),

    // APPLICATION CHECK LIST
    applicationCheckStatus: builder.query({
      query: (id) => URL.APPLICATION_CHECK_STATUS + id,
    }),

    // APPLICATION CHECK ADD
    applicationCheckAdd: builder.mutation({
      query: (payload) => ({
        url: URL.APPLICATION_CHECK_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    // APPLICATION CHECK UPDATE
    applicationCheckUpdate: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.APPLICATION_CHECK_UPDATE + id,
        body: payload,
        method: "POST",
      }),
    }),

    // APPLICATION DOCUMENT LIST
    applicationDocumentList: builder.query({
      query: () => URL.APPLICATION_DOCUMENT_LIST,
    }),

    // APPLICATION DOCUMENT VIEW
    applicationDocumentView: builder.query({
      query: (id) => URL.APPLICATION_DOCUMENT_VIEW + id,
    }),

    // APPLICATION DOCUMENT STATUS
    applicationDocumentStatus: builder.query({
      query: (id) => URL.APPLICATION_DOCUMENT_STATUS + id,
    }),

    // APPLICATION DOCUMENT ADD
    applicationDocumentAdd: builder.mutation({
      query: (payload) => ({
        url: URL.APPLICATION_DOCUMENT_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    // APPLICATION DOCUMENT UPDATE
    applicationDocumentUpdate: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.APPLICATION_DOCUMENT_UPDATE + id,
        body: payload,
        method: "POST",
      }),
    }),

    // SITE DETAIL QUESTION LIST
    siteDetailsList: builder.query({
      query: () => URL.SITE_DETAIL_QUESTION_LIST,
    }),

    // SITE DETAIL QUESTION VIEW
    siteDetailsView: builder.query({
      query: (id) => URL.SITE_DETAIL_QUESTION_VIEW + id,
    }),

    // SITE DETAIL QUESTION STATUS
    siteDetailsStatus: builder.query({
      query: (id) => URL.SITE_DETAIL_QUESTION_STATUS + id,
    }),

    // SITE DETAIL QUESTION ADD
    siteDetailsAdd: builder.mutation({
      query: (payload) => ({
        url: URL.SITE_DETAIL_QUESTION_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    // SITE DETAIL QUESTION UPDATE
    siteDetailsUpdate: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.SITE_DETAIL_QUESTION_UPDATE + id,
        body: payload,
        method: "POST",
      }),
    }),

    // PROPOSED ACTIVTY LIST
    proposedActivityList: builder.query({
      query: () => URL.PROPOSED_ACTIVITY_LIST,
    }),

    // PROPOSED ACTIVTY VIEW
    proposedActivityView: builder.query({
      query: (id) => URL.PROPOSED_ACTIVITY_VIEW + id,
    }),

    // PROPOSED ACTIVTY STATUS
    proposedActivityStatus: builder.query({
      query: (id) => URL.PROPOSED_ACTIVITY_STATUS + id,
    }),

    // PROPOSED ACTIVTY ADD
    proposedActivityAdd: builder.mutation({
      query: (payload) => ({
        url: URL.PROPOSED_ACTIVITY_ADD,
        body: payload,
        method: "POST",
      }),
    }),

    // PROPOSED ACTIVTY UPDATE
    proposedActivityUpdate: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.PROPOSED_ACTIVITY_UPDATE + id,
        body: payload,
        method: "POST",
      }),
    }),

    // USER LIST
    userList: builder.query({
      query: () => URL.USER_LIST,
    }),

    // USER VIEW
    userView: builder.query({
      query: (id) => URL.USER_VIEW + id,
    }),

    // USER PASSWORD CHANGE
    userPasswordChange: builder.mutation({
      query: (payload) => ({
        url: URL.USER_PASSWORD_CHANGE,
        body: payload,
        method: "POST",
      }),
    }),

    // USER APPLICATION LIST
    userApplicationList: builder.query({
      query: () => URL.USER_APPLICATION_LIST,
    }),

    // USER APPLICATION DELET
    userApplicationDelete: builder.mutation({
      query: (payload) => ({
        url: URL.USER_APPLICATION_DELETE,
        body: payload,
        method: "POST",
      }),
    }),

    // QUEUE LIST
    queueList: builder.query({
      query: () => URL.QUEUE_LIST,
    }),

    // QUEUE CLEAR
    queueClear: builder.mutation({
      query: (payload) => ({
        url: URL.QUEUE_CLEAR,
        body: payload,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyLogoutQuery,
  useChangePasswordMutation,
  useLazyRoleCreateQuery,
  useRoleEditMutation,
  useRoleStoreMutation,
  useLazyRoleStatusQuery,
  useLazyRoleViewQuery,
  useLazyRolesQuery,
  useActiveRolesQuery,
  useLazyAdminsQuery,
  useAdminAddMutation,
  useAdminEditMutation,
  useLazyAdminViewQuery,
  useLazyAdminStatusQuery,
  usePalceEditMutation,
  usePlaceAddMutation,
  useLazyPalceViewQuery,
  useLazyPlaceParentListQuery,
  useLazyPlacesQuery,
  useLazyApplicationCheckListQuery,
  useLazyApplicationCheckStatusQuery,
  useLazyApplicationCheckViewQuery,
  useApplicationCheckAddMutation,
  useApplicationCheckUpdateMutation,
  useApplicationDocumentAddMutation,
  useApplicationDocumentUpdateMutation,
  useLazyApplicationDocumentListQuery,
  useLazyApplicationDocumentStatusQuery,
  useLazyApplicationDocumentViewQuery,
  useLazySiteDetailsListQuery,
  useLazySiteDetailsStatusQuery,
  useLazySiteDetailsViewQuery,
  useSiteDetailsAddMutation,
  useSiteDetailsUpdateMutation,
  useLazyProposedActivityListQuery,
  useLazyProposedActivityStatusQuery,
  useLazyProposedActivityViewQuery,
  useProposedActivityAddMutation,
  useProposedActivityUpdateMutation,
  useLazyUserListQuery,
  useLazyUserViewQuery,
  useUserPasswordChangeMutation,
  useLazyUserApplicationListQuery,
  useUserApplicationDeleteMutation,
  useLazyQueueListQuery,
  useQueueClearMutation,
} = api;
