import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

const Document = () => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();

  const showHandler = (type) => {
    setModalData("https://www.africau.edu/images/default/sample.pdf");

    setModal(true);
  };
  return (
    <div className="bg-light my-2">
      <div className="generallinformation">
        <div className="block block-rounded p-2">
          <div className="block-content block-content-full">
            <div className="row">
              <Col lg={12}>
                <div className="blocks block-rounded tables">
                  <table className="tablelist table-borderless user-det">
                    <thead>
                      <th
                        colspan="3"
                        className="h5 text-light mb-0 font-weight-light p-3"
                      >
                        PRIMARY / ADDITTIONAL / MSB HR
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Location (Topographical) Plan
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Declaration forms duly singed by the owner and
                          attested by Gazetted Officer.
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          License copy of Architect renewed by COA (or) License
                          copy of the Engineer / Surveyor issued by concern
                          authority attested by Gazetted Officer.
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Sale agreement/ Sale deed, Lease agreement/ Lease deed
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Link documents documents of last 5 years(Attested by
                          Gazz. Officer)
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Land Use Certificate/master plan extract/ICT issued by
                          concern authority
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Link document/ Mutation orders of last 5
                          years(Attested by Gazz. Officer)
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Structural Design drawings.
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Soil test Report.</td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Third party proof check
                        </td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Common Affidavit</td>
                        <td>:</td>
                        <td>
                          <button
                            className="appli-btn"
                            onClick={() => showHandler("")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="w-100 justify-content-center">
            <iframe
              src={modalData}
              style={{ height: "500px" }}
              className="w-100"
            ></iframe>
            {/* <div className="d-flex w-50">
              <img
                src={modalData}
                className="w-100 text-center mt-2 mb-2"
              ></img>
            </div> */}
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Document;
