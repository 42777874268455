import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useLazyRoleCreateQuery,
  useLazyRoleViewQuery,
  useRoleEditMutation,
  useRoleStoreMutation,
} from "../../../../redux/api/api";
import { modules } from "../../../DummyData";
import Topbar from "../../Topbar";

const AddRole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const types = location?.state?.type;
  const [permissionList, setPermissionList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [role, setRole] = useState("");
  const [roleErr, setRoleErr] = useState(false);
  const [btn, setBtn] = useState(false);

  // RTQ QUERY
  const [rolesApi] = useLazyRoleCreateQuery();
  const [roleAddApi] = useRoleStoreMutation();
  const [roleEditApi] = useRoleEditMutation();
  const [roleViewApi] = useLazyRoleViewQuery();

  const getRoleModule = () => {
    rolesApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setPermissionList(res?.modules);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const rolefun = (id) => {
    let roleId = [...permissions];

    if (roleId.includes(id)) {
      let ids = roleId.indexOf(id);
      roleId.splice(ids, 1);
      setPermissions(roleId);
    } else {
      roleId.push(id);
      setPermissions(roleId);
    }
  };

  const getView = () => {
    roleViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        // console.log("res", res);
        if (res?.status == "success") {
          let data = res?.role;
          setRole(data?.role);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (types == "edit") {
      getView();
    }
  }, []);

  const submitHandler = () => {
    if (role?.length == 0) {
      setRoleErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("role", role);

      permissions?.map((item, ind) => {
        formdata.append(`permissions[${ind}]`, item);
      });
      setBtn(true);
      if (types == "edit") {
        formdata.append("_method", "PUT");
        roleEditApi({ id: location?.state?.data?.id, payload: formdata })
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              navigate("/roles");
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      } else {
        roleAddApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              navigate("/roles");
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  useEffect(() => {
    getRoleModule();
    // setPermissionList(modules);
  }, []);

  return (
    <div>
      <Topbar type={"role"} />
      <div className="content">
        <div className="block bg-light block-rounded">
          <div className="block-content user-frm">
            <Row className="mb-5">
              <Col md={12} lg={12} sm={12}>
                <div className="form-group position-relative">
                  <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                    Role Name *
                  </label>
                  <div className="mt-2">
                    <input
                      className="add-admin-frm fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-15 fs-xxl-16 w-25"
                      onChange={(e) => setRole(e.target.value)}
                      value={role}
                    />
                  </div>
                  {role?.length == 0 && roleErr && (
                    <p className="err">Enter Role</p>
                  )}
                </div>
              </Col>
              <Col md={12} lg={12} sm={12}>
                <div className="form-group">
                  <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 ">
                    Permission:
                  </label>
                </div>
              </Col>
              {permissionList?.map((items, i) => {
                return (
                  <Col sm={4} className="my-2">
                    <p
                      key={i}
                      className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20"
                    >
                      {items.module_type}
                    </p>

                    {items?.permissions?.map((list, i) => {
                      return (
                        <>
                          <Form.Check
                            key={i}
                            type="switch"
                            className="mt-2"
                            id="custom-switch"
                            name="ratingCheckbox"
                            checked={permissions.includes(list?.id)}
                            onClick={() => {
                              rolefun(list.id);
                            }}
                            label={
                              <p className="fs-xs-10 fs-sm-11 fs-md-12 fs-lg-12 fs-xl-13 fs-xxl-15">
                                {list.code}
                              </p>
                            }
                          />
                        </>
                      );
                    })}
                  </Col>
                );
              })}
              {permissions?.length > 1 && (
                <div className="form-btn mt-5">
                  <button
                    disabled={btn ? true : false}
                    onClick={() => submitHandler()}
                  >
                    Submit
                  </button>
                </div>
              )}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRole;
