// @ts-nocheck
import React, { useState } from "react";
import { Avatar, Brand, Logo } from "../../../assets";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";

const NavBarTopStaff = () => {
  const [actionBtn, setActionBtn] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="page-header">
      <div className="content-header">
        <div className="d-flex align-items-center"></div>
        <div className="d-flex align-items-center">
          <p className="text-center">commr1-cyb</p>
          <p className="text-center">/Commissioner</p>
          <div>
            <button
              onClick={() => setActionBtn(!actionBtn)}
              className="position-relative  drop-btn drop-btns  mt-sm-3 ms-1 text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-2 rounded"
            >
              <img src={Avatar} className="mx-1" />
              TSIIC
              <ArrowDropDownIcon className="mx-1" />
            </button>
            {actionBtn && (
              <div className="action_btn ml-1">
                <button
                  className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                  onClick={() => navigate("/staff/signin")}
                >
                  <div className="logout">
                    <p className="mt-2"> Staff Logout</p>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBarTopStaff;
