import React from "react";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Col, Row } from "react-bootstrap";

const Topbar = ({ type }) => {
  const navigate = useNavigate();
  return (
    <>
      <Row className="bg-body-light">
        {/* ADMIN */}
        {type == "admin" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Admin Create
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/admin")}
                  >
                    Admin
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Create
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {type == "admins" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Admin List
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/admin")}
                  >
                    Admin
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* ROLES */}

        {type == "roles" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Role List
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/roles")}
                  >
                    Role
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {type == "role" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Role Create
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/roles")}
                  >
                    Role
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Create
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* USERS */}

        {type == "users" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                User List
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/users")}
                  >
                    User
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {type == "user" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                User View
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/users")}
                  >
                    User
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    View
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {type == "user-password" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                User Password Edit
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/users")}
                  >
                    User
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Password Edit
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* USER APPLICTION */}
        {type == "user-app" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                User Application List
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/users-application")}
                  >
                    User Application List
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* PLACES */}
        {type == "places" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Place
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Place
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* BUILDING DETAILS */}
        {type == "building-details" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Building Details
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Building Details
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* site-details-question */}
        {type == "site-details-question" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Site Details Question List
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Site Details Question
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}
        {/* site-details-question edit */}
        {type == "site-details-questions-edit" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Site Details Question
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate(-1)}
                  >
                    Site Details Question
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}
        {/* site-details-question add */}
        {type == "site-details-questions-add" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Site Details Question
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate(-1)}
                  >
                    Site Details Question
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Create
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* application-document */}
        {type == "application-document" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Application Document List
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Application Document
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* application-document edit */}
        {type == "application-documents-edit" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Application Document
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate(-1)}
                  >
                    Application Document
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}
        {/* application-document add */}
        {type == "application-documents-add" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Application Document
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate(-1)}
                  >
                    Application Document
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Create
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* application-check */}
        {type == "application-checklist" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Application Check List
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Application Check
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* application-check edit */}
        {type == "application-checklist-edit" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Application Check
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate(-1)}
                  >
                    Application Check
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}
        {/* application-check add */}
        {type == "application-checklist-add" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Application Check
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate(-1)}
                  >
                    Application Check
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Create
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* proposed-activity */}
        {type == "proposed-activity" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Proposed Activity List
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Proposed Activity List
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* proposed-activity edit */}
        {type == "proposed-activity-edit" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Proposed Activity
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate(-1)}
                  >
                    Proposed Activity
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}
        {/* application-check add */}
        {type == "proposed-activity-add" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Proposed Activity
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate(-1)}
                  >
                    Proposed Activity
                  </button>
                  <NavigateNextIcon />
                  <button className="active fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15">
                    Create
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}

        {/* queues list */}

        {type == "queues" && (
          <Col
            md={12}
            lg={12}
            sm={12}
            className="d-flex justify-content-between"
          >
            <div className="p-2">
              <h5 className="fs-xs-13 fs-sm-13 fs-md-15 fs-lg-18 fs-xl-18 fs-xxl-20 p-4">
                Queue List
              </h5>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center p-2">
              <div className="flex-sm-00-auto ml-sm-3 p-4">
                <div className="d-flex navi-btn">
                  <button
                    className="fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/dashboard")}
                  >
                    Dashboard
                  </button>
                  <NavigateNextIcon />
                  <button
                    className="navi-btn fs-xs-11 fs-sm-11 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-15"
                    onClick={() => navigate("/queue-list")}
                  >
                    Queue
                  </button>
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Topbar;
