// @ts-nocheck
import React from "react";
import { Brand, Logo } from "../../../assets";

const NavBarTop = () => {
  return (
    <div className="content-header bg-white-5" style={{ height: "4rem" }}>
      <h5 className="mx-2 text-white">TSIIC</h5>
      <div className="">
        <img
          src={Brand}
          alt="logo"
          className="img-fluid  text-center my-2 bg-white p-1 rounded"
        />
      </div>
    </div>
  );
};

export default NavBarTop;
