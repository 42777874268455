// export const BASE_URL = "http://192.168.1.28/tsiic-backend-aus/public/crmapi/"; // local
export const BASE_URL =
  // "https://tsiicaustraliascript.premiumfreshfoods.com/public/crmapi/";
  // "http://tsiicscript.ninositsolution.com/public/crmapi/";
  "http://ausbackend.autometrybpas.co.in/public/crmapi/"; // LIVE

export const URL = {
  LOGIN: "admin-login",
  LOGOUT: "admin/logout",
  CHANGE_PASSWORD: "change-password",
  TOKEN_VERIFY: "admin-token-verify",
  SET_PASSWORD: "admin-set-password",

  // ROLE
  ROLE: "role",
  ACTIVE_ROLES: "active-roles",

  // ADMIN
  ADMIN: "admin",

  // PLACE
  PLACE_LIST: "placelist",
  PLACE_ADD: "placeadd",
  PLACE_PARENT_lIST: "placeparentlist/",
  PLACE_EDIT: "place/edit/",
  PLACE_UPDATE: "place/update/",

  // APPLICATION CHECK
  APPLICATION_CHECK_LIST: "application-checklist",
  APPLICATION_CHECK_ADD: "application-checklist/add",
  APPLICATION_CHECK_VIEW: "application-checklist/edit/",
  APPLICATION_CHECK_UPDATE: "application-checklist/update/",
  APPLICATION_CHECK_STATUS: "application-checklist-status/status/",

  // APPLICATION DOCUMENT
  APPLICATION_DOCUMENT_LIST: "application-documentlist",
  APPLICATION_DOCUMENT_ADD: "application-documentadd",
  APPLICATION_DOCUMENT_VIEW: "application-document/edit/",
  APPLICATION_DOCUMENT_UPDATE: "application-document/update/",
  APPLICATION_DOCUMENT_STATUS: "application-document/status/",

  // SITE DETAIL QUESTION
  SITE_DETAIL_QUESTION_LIST: "site_detail-questionlist",
  SITE_DETAIL_QUESTION_ADD: "site_detail-questionadd",
  SITE_DETAIL_QUESTION_VIEW: "site_detail-question/edit/",
  SITE_DETAIL_QUESTION_UPDATE: "site_detail-question/update/",
  SITE_DETAIL_QUESTION_STATUS: "site_detail-question/status/",

  // PROPOSED ACTIVTY
  PROPOSED_ACTIVITY_LIST: "proposed_activitylist",
  PROPOSED_ACTIVITY_ADD: "proposed_activityadd",
  PROPOSED_ACTIVITY_VIEW: "proposed_activity/edit/",
  PROPOSED_ACTIVITY_UPDATE: "proposed_activity/update/",
  PROPOSED_ACTIVITY_STATUS: "proposed_activity/status/",

  // USER
  USER_VIEW: "userview/",
  USER_LIST: "userlist",
  USER_PASSWORD_CHANGE: "user-password-update",

  // USER APPLICATION
  USER_APPLICATION_LIST: "all/user-applications",
  USER_APPLICATION_DELETE: "user-application/delete",

  // QUEUE
  QUEUE_LIST: "scrutiny-engine-queues",
  QUEUE_CLEAR: "scrutiny-engine-queues/clear-queue",
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";

export const NUMBER = /^\+?(0|[6-9]\d*)$/;
export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const productDetailImg = {
  loop: true,
  autoplay: false,
  center: true,
  dots: true,
  autoplayTimeout: 3000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
  },
};
