import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import EightFiles from "./dashboards/EightFiles";
import NineFiles from "./dashboards/NineFiles";
import GroupIcon from "@mui/icons-material/Group";

function StaffDashboard() {
  const [dashBoard, setDashBoard] = useState([]);
  const [dash, setDash] = useState(1);
  // console.log("dash", dash);

  useEffect(() => {
    let temp = {
      total_files: 12,
      total_sanction_files: 2,
      total_rejected_files: 3,
      total_commissioner_files: 5,
      commissioner_approved: 8,
      commissioner_short_fall: 4,
      commissioner_short_fall_resubmited: 3,
      demand_note_generated: 9,
      zonal_manager_application_list: 4,
      zonal_manager_approved: 7,
      zonal_manager_short_fall: 6,
      zonal_manager_short_fall_resumitted: 4,
      demand_note_approved: 3,
      demand_note_issued: 8,
      payment_completed: 4,
      upload_file_documents: 1,
      hr_ce_technical_approvel: 2,
      dgm_drawing_declined: 6,
      high_rise_demand_note_approved: 0,
      technical_approved: 5,
      sanction_corrections: 7,
      sanction_user_approved: 3,
      digital_signature: 11,
    };

    setDashBoard(temp);
  }, []);

  return (
    <div className="content bg_staff">
      <div className="d-flex flex-wrap mb-5">
        <Col md={3} lg={3} className="px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(1)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1 ">
                  Total Files
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.total_files}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 1 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(2)}>
            <div className="px-3 py-3 d-flex ">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Total Sanction Files
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.total_sanction_files}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 2 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(3)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Total Rejected Files
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.total_rejected_files}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 3 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className="px-2 mb-3">
          <div onClick={() => setDash(4)} className="dash_box block-link-pop">
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Total Commissioner Files
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.total_commissioner_files}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 4 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(5)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Commissioner Approved
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.commissioner_approved}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 5 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className="px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(6)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Commissioner Short Fall
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.commissioner_short_fall}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 6 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(7)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Commissioner Short Fall Resubmitted
                </div>
                <div class="font-size-h3 mt-2">
                  {dashBoard?.commissioner_short_fall_resubmited}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 7 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className="px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(8)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Demand Note Generated
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.demand_note_generated}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 8 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(9)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Zonal Manager Application List
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.zonal_manager_application_list}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 9 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(10)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Zonal Manager Approved
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.zonal_manager_approved}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 10 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(11)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Zonal Manager Short Fall
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.zonal_manager_short_fall}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 11 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(12)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Zonal Manager Short Fall Resubmitted
                </div>
                <div class="font-size-h3 mt-2">
                  {dashBoard?.zonal_manager_short_fall_resumitted}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 12 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(13)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Demand Note Approved
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.demand_note_approved}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 13 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(14)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Demand Note Issued
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.demand_note_issued}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 14 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(15)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Payment Completed
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.payment_completed}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 15 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(16)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Uploaded File Documents
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.upload_file_documents}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 16 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(17)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  HR CE-TECHNICAL APPROVAL
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.hr_ce_technical_approvel}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 17 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(18)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  DGM Drawing Declined
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.dgm_drawing_declined}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 18 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(19)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  High rise Demand Note Approved
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.high_rise_demand_note_approved}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 19 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(20)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Technical Approved
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.technical_approved}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 20 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(21)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Sanction Corrections
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.sanction_corrections}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 21 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(22)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Sanction User Approved
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.sanction_user_approved}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 22 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={3} lg={3} className=" px-2 mb-3">
          <div className="dash_box block-link-pop" onClick={() => setDash(23)}>
            <div className="px-3 py-3 d-flex">
              <div class="flex-grow-1">
                <div class="dash-icon">
                  <GroupIcon />
                </div>
                <div class="font-size-sm font-w600 text-uppercase text-muted staf-dash-tit mt-1">
                  Digital Signature
                </div>
                <div class="font-size-h3 mt-4 mb-2">
                  {dashBoard?.digital_signature}
                </div>
              </div>
              <div class="d-flex ml-2 mt-4">
                <div
                  class={`flex-grow-1 px-1 rounded-lg ${
                    dash == 23 ? "bg-success-light" : "bg-danger-light"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </Col>
      </div>
      {dash == 1 ? (
        <EightFiles type={"total_files"} />
      ) : dash == 2 ? (
        <EightFiles type={"total_sanction_files"} />
      ) : dash == 3 ? (
        <EightFiles type={"total_rejected_files"} />
      ) : dash == 4 ? (
        <EightFiles type={"total_commissioner_files"} />
      ) : dash == 5 ? (
        <EightFiles type={"commissioner_approved"} />
      ) : dash == 6 ? (
        <EightFiles type={"commissioner_short_fall"} />
      ) : dash == 7 ? (
        <EightFiles type={"commissioner_short_fall_resubmited"} />
      ) : dash == 8 ? (
        <EightFiles type={"demand_note_generated"} />
      ) : dash == 9 ? (
        <EightFiles type={"zonal_manager_application_list"} />
      ) : dash == 10 ? (
        <EightFiles type={"zonal_manager_approved"} />
      ) : dash == 11 ? (
        <EightFiles type={"zonal_manager_short_fall"} />
      ) : dash == 12 ? (
        <EightFiles type={"zonal_manager_short_fall_resumitted"} />
      ) : dash == 13 ? (
        <EightFiles type={"demand_note_approved"} />
      ) : dash == 14 ? (
        <EightFiles type={"demand_note_issued"} />
      ) : dash == 15 ? (
        <EightFiles type={"payment_completed"} />
      ) : dash == 16 ? (
        <EightFiles type={"upload_file_documents"} />
      ) : dash == 17 ? (
        <EightFiles type={"hr_ce_technical_approvel"} />
      ) : dash == 18 ? (
        <NineFiles type={"dgm_drawing_declined"} />
      ) : dash == 19 ? (
        <EightFiles type={"high_rise_demand_note_approved"} />
      ) : dash == 20 ? (
        <EightFiles type={"technical_approved"} />
      ) : dash == 21 ? (
        <EightFiles type={"sanction_corrections"} />
      ) : dash == 22 ? (
        <EightFiles type={"sanction_user_approved"} />
      ) : dash == 23 ? (
        <EightFiles type={"digital_signature"} />
      ) : null}
    </div>
  );
}

export default StaffDashboard;
