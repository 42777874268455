import React from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";

const SiteDetails = () => {
  return (
    <div className="bg-light my-2">
      <div>
        <div className="bg-body-light">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1
                className="flex-sm-fill h3 my-2"
                style={{
                  fontSize: "1.5rem",
                  color: "#373737",
                  lineHeight: "1.25",
                  fontFamily: "f2",
                }}
              >
                Site Details{" "}
              </h1>
              <div className="flex-sm-00-auto ml-sm-3">
                <div className="d-flex navi-btn">
                  <button className="active">Site Details</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="block block-rounded">
            <div className="block-content block-content-full">
              <Row>
                <Col lg={12}>
                  <div className="blocks block-rounded">
                    <div className="table-responsive">
                      <table className="table b-table table-view">
                        <thead className="bg-white rounded border-top-0 border-bottom-0 mb-2">
                          <tr className="border-top-0 border-bottom-0 rounded-top mb-2 text-center">
                            <th className="text-center border-top-0 border-bottom-0">
                              S.No
                            </th>
                            <th className="text-center border-top-0 border-bottom-0">
                              DESCRIPTION
                            </th>
                            <th className="text-center border-top-0 border-bottom-0">
                              INPUT BY APPLICANT{" "}
                            </th>
                            <th className="text-center border-top-0 border-bottom-0">
                              INPUT BY AUTHORITY{" "}
                            </th>
                            <th className="text-center border-top-0 border-bottom-0">
                              STATUS
                            </th>
                          </tr>
                        </thead>
                        <tbody className="mt-3 bg-white">
                          <tr className="text-center">
                            <td>1</td>
                            <td>Whether approach road existing at site </td>
                            <td>
                              <input
                                className="add-admin-frm"
                                disabled
                                value={5}
                              />
                            </td>
                            <td>
                              <input className="add-admin-frm" />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="text-center">
                            <td>2</td>
                            <td>
                              Whether approach road connected with an existing
                              public road{" "}
                            </td>
                            <td>
                              <input
                                className="add-admin-frm"
                                disabled
                                value={1}
                              />
                            </td>
                            <td>
                              <input className="add-admin-frm" />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="text-center">
                            <td>3</td>
                            <td>Nature of approach road </td>
                            <td>
                              <input
                                className="add-admin-frm"
                                disabled
                                value={8}
                              />
                            </td>
                            <td>
                              <input className="add-admin-frm" />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="text-center">
                            <td>4</td>
                            <td>Width of approach road in meters</td>
                            <td>
                              <input
                                className="add-admin-frm"
                                disabled
                                value={3}
                              />
                            </td>
                            <td>
                              <input className="add-admin-frm" />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="text-center">
                            <td>5</td>
                            <td>Services over approach road </td>
                            <td>
                              <input
                                className="add-admin-frm"
                                disabled
                                value={7}
                              />
                            </td>
                            <td>
                              <input className="add-admin-frm" />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="text-center">
                            <td>6</td>
                            <td>Whether approach road side drain exists </td>
                            <td>
                              <input
                                className="add-admin-frm"
                                disabled
                                value={4}
                              />
                            </td>
                            <td>
                              <input className="add-admin-frm" />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="text-center">
                            <td>7</td>
                            <td>Whether the site is vaccant </td>
                            <td>
                              <input
                                className="add-admin-frm"
                                disabled
                                value={2}
                              />
                            </td>
                            <td>
                              <input className="add-admin-frm" />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="text-center">
                            <td>8</td>
                            <td>FEES PAID </td>
                            <td>
                              <input
                                className="add-admin-frm"
                                disabled
                                value={6}
                              />
                            </td>
                            <td>
                              <input className="add-admin-frm" />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="text-center">
                            <td>9</td>
                            <td>Work(Commenced/Not Commenced) </td>
                            <td>
                              <input
                                className="add-admin-frm"
                                disabled
                                value={5}
                              />
                            </td>
                            <td>
                              <input className="add-admin-frm" />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="text-center">
                            <td>10</td>
                            <td>
                              If Commenced, the status of construction at the
                              time of Inspection
                            </td>
                            <td>
                              <input
                                className="add-admin-frm"
                                disabled
                                value={9}
                              />
                            </td>
                            <td>
                              <input className="add-admin-frm" />
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="content bg-white p-4 mt-4 mb-4">
                      <Row>
                        <Col className="mb-0 mt-3" lg={6}>
                          <p
                            className="mb-1"
                            style={{
                              lineHeight: "1.6",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            North Image
                          </p>
                          <div class="input-group mb-2 mt-2">
                            <div
                              class="custom-file"
                              style={{
                                border: "1px solid rgb(213 220 225)",
                                borderRadius: "5px",
                              }}
                            >
                              <input
                                type="file"
                                class="custom-file-input"
                                id="inputGroupFile04"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col className="mb-0 mt-3" lg={6}>
                          <p
                            className="mb-1"
                            style={{
                              lineHeight: "1.6",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            South Image
                          </p>
                          <div class="input-group mb-2 mt-2">
                            <div
                              class="custom-file"
                              style={{
                                border: "1px solid rgb(213 220 225)",
                                borderRadius: "5px",
                              }}
                            >
                              <input
                                type="file"
                                class="custom-file-input"
                                id="inputGroupFile04"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col className="mb-0 mt-3" lg={6}>
                          <p
                            className="mb-1"
                            style={{
                              lineHeight: "1.6",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            East Image
                          </p>
                          <div class="input-group mb-2 mt-2">
                            <div
                              class="custom-file"
                              style={{
                                border: "1px solid rgb(213 220 225)",
                                borderRadius: "5px",
                              }}
                            >
                              <input
                                type="file"
                                class="custom-file-input"
                                id="inputGroupFile04"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col className="mb-0 mt-3" lg={6}>
                          <p
                            className="mb-1"
                            style={{
                              lineHeight: "1.6",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            West Image
                          </p>
                          <div class="input-group mb-2 mt-2">
                            <div
                              class="custom-file"
                              style={{
                                border: "1px solid rgb(213 220 225)",
                                borderRadius: "5px",
                              }}
                            >
                              <input
                                type="file"
                                class="custom-file-input"
                                id="inputGroupFile04"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col className="mb-0 mt-3" lg={6}>
                          <p
                            className="mb-1"
                            style={{
                              lineHeight: "1.6",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            Comments
                          </p>
                          <div>
                            <div className="form-group">
                              <textarea
                                className="form-control_draw"
                                style={{ height: "auto" }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="col-12 mx-auto">
                      <div className="col-12 d-flex tex-center mx-auto justify-content-center pb-5 gap-2">
                        <div>
                          <button className="btn text-black btn-success">
                            Submit
                          </button>
                        </div>
                        <div>
                          <button className="btn text-black btn-danger">
                            Reject
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteDetails;
