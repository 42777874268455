import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

const Drawing = () => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [check, setCheck] = useState(0);

  const showHandler = (type) => {
    setModalData("https://www.africau.edu/images/default/sample.pdf");

    setModal(true);
  };
  return (
    <div className="bg-light my-2">
      <div className="generallinformation">
        <div className="block block-rounded p-2">
          <div className="block-content block-content-full">
            <div className="row">
              <Col lg={12}>
                <div className="blocks block-rounded tables">
                  <table className="tablelist table-borderless user-det">
                    <thead>
                      <th
                        colspan="3"
                        className="h5 text-light mb-0 font-weight-light p-3"
                      >
                        DETAILS
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>Scrutiny Report</td>
                        <td>:</td>
                        <td>
                          <Row>
                            <Col md={2}>
                              <p></p>
                            </Col>
                            <Col md={2}>
                              <button
                                className="appli-btn"
                                onClick={() => showHandler("")}
                              >
                                View
                              </button>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Drawing Report Pdf</td>
                        <td>:</td>
                        <td>
                          <Row>
                            <Col md={2}>
                              <p></p>
                            </Col>
                            <Col md={2}>
                              <button
                                className="appli-btn"
                                onClick={() => showHandler("")}
                              >
                                View
                              </button>
                            </Col>
                            <Col md={9}>
                              <Row>
                                <Col md={5}>
                                  <div class="form-group cursor-pointer mt-2">
                                    <div class="form-check form-check-inline">
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        id="inlineRadio1"
                                        value="1"
                                        onClick={() => setCheck(1)}
                                        checked={check == 1}
                                      />
                                      <label
                                        class="form-check-label"
                                        for="inlineRadio1"
                                      >
                                        Accept
                                      </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        id="inlineRadio2"
                                        value="2"
                                        onClick={() => setCheck(2)}
                                        checked={check == 2}
                                      />
                                      <label
                                        class="form-check-label"
                                        for="inlineRadio2"
                                      >
                                        Decline
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            {check == 2 && (
                              <Col md={10}>
                                <div>
                                  <div className="form-group">
                                    <textarea
                                      className="form-control_draw"
                                      placeholder="write a reason..."
                                      style={{ height: "auto" }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            )}
                            <Col md={2}>
                              <button className="btn text-black btn-success">
                                Submit
                              </button>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="w-100 justify-content-center">
            <iframe
              src={modalData}
              style={{ height: "500px" }}
              className="w-100"
            ></iframe>
            {/* <div className="d-flex w-50">
              <img
                src={modalData}
                className="w-100 text-center mt-2 mb-2"
              ></img>
            </div> */}
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Drawing;
