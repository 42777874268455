import React, { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { GlobalFilter } from "../../../staff/components/dashboards/GlobalFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Topbar from "../../Topbar";
import {
  useLazyUserApplicationListQuery,
  useUserApplicationDeleteMutation,
} from "../../../../redux/api/api";
import Swal from "sweetalert2";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show"></Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="text-center  mb-2 py-3"
            >
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="text-center  mb-2 py-3"
                  style={{ fontFamily: "f1", fontSize: "14px" }}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="text-center  py-2">
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="text-center py-2">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const UserApplication = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "APPLICATION NUMBER",
        accessor: "file_no",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "APPLICATION TYPE",
        accessor: "application_type",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "CASE TYPE",
        accessor: "case_type",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "APPLICANT NAME	",
        accessor: "user",
        Cell: ({ value, row }) => {
          return <p>{value.first_name}</p>;
        },
      },
      {
        Header: "TYPE",
        accessor: "iala_names",
        Cell: ({ value, row }) => {
          return (
            <p>
              {" "}
              {row.original.user.type == 1
                ? "User"
                : row.original.user.type == 2
                ? "Architect"
                : "Structural Engineer"}
            </p>
          );
        },
      },
      {
        Header: "IALA",
        accessor: "iala_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "STATUS",
        accessor: "status_word",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },

      {
        Header: "ACTION",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <button
                className="comn-btn  edit-btn-svg bg-transparent border-0 text-danger"
                onClick={() => deleteApplication(row.original.id)}
              >
                <DeleteForeverIcon />
              </button>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  // RTK QUERY
  const [userApplicationApi] = useLazyUserApplicationListQuery();
  const [userApplicationDeleteApi] = useUserApplicationDeleteMutation();

  const getList = () => {
    userApplicationApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.user_applications);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const deleteApplication = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let formdata = new FormData();
        formdata.append("user_application_id", id);
        userApplicationDeleteApi(formdata)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              alert(res?.message);
              getList();
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div>
      <Topbar type={"user-app"} />
      <div className="p-3">
        <div className="block bg-light block-rounded">
          <div className="block-content">
            <Table columns={columns} data={list} className="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserApplication;
