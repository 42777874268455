import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

const PlotDetails = () => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();

  const showHandler = (type) => {
    setModalData(
      "http://tsiicscript.autometrybpas.co.in/public/boundry_images/boundry-2022112262820737.jpg"
    );
    setModal(true);
  };

  return (
    <div className="bg-light my-2">
      <div className="generallinformation">
        <div className="block block-rounded p-2">
          <div className="block-content block-content-full">
            <div className="row">
              <Col lg={12}>
                <div className="blocks block-rounded tables">
                  <table className="tablelist table-borderless user-det">
                    <thead>
                      <th
                        colspan="3"
                        className="h5 text-light mb-0 font-weight-light p-3"
                      >
                        MAIN DETAILS
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>Proposed Use</td>
                        <td>:</td>
                        <td>Residential</td>{" "}
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Proposed Activity</td>
                        <td>:</td>
                        <td>Villa</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Plot Area As Per Plan (Sq.Mt.)
                        </td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Plot Area As per Documents (Sq.Mt.)
                        </td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Plot Area As On Grounds (Sq.Mt.)
                        </td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Proposed Built Up Area (Sq.Mt.)
                        </td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Existing Built up Area (Sq.Mt.)
                        </td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Is site affecting under proposed
                          master/SRDP/RDP/Layout Plan roads?
                        </td>
                        <td>:</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Plot Width (Mt.)</td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Plot Length (Mt.)</td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>No Of Block</td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Storey Type</td>
                        <td>:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>No. of Storey's</td>
                        <td>:</td>
                        <td>Ground</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>C(Cellar)</td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Plot Nearby Religious Structures
                        </td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Location Type</td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Sub Location</td>
                        <td>:</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Land Use Zone</td>
                        <td>:</td>
                        <td>Other Municipal Corporations</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Aerodrome Type</td>
                        <td>:</td>
                        <td>NA</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Aero-distance</td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Is this plot part of</td>
                        <td>:</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Sanction No.</td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Sanction Date</td>
                        <td>:</td>
                        <td></td>
                      </tr>
                      <tr class="bg-purple p-2 rounded my-2">
                        <td
                          colspan="3"
                          class="h5_2 text-light mb-0 font-weight-light p-3"
                        >
                          Sale Deed Details
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Sale deed executed</td>
                        <td>:</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Sale deed expansion</td>
                        <td>:</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Sale deed revised permit
                        </td>
                        <td>:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Sale deed No</td>
                        <td>:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Sale deed Date</td>
                        <td>:</td>
                        <td></td>
                      </tr>
                      <tr class="bg-purple p-2 rounded my-2">
                        <td
                          colspan="3"
                          class="h5_2 text-light mb-0 font-weight-light p-3"
                        >
                          Site Details
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Approach Road , Whether existing on site
                        </td>
                        <td>:</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Whether Approached Road connected with existing Public
                          Road Plan
                        </td>
                        <td>:</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Status of Road</td>
                        <td>:</td>
                        <td>Public</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Commencement Of Work On Site
                        </td>
                        <td>:</td>
                        <td>Yes</td>
                      </tr>
                      <tr class="bg-purple p-2 rounded my-2">
                        <td
                          colspan="3"
                          class="h5_2 text-light mb-0 font-weight-light p-3"
                        >
                          Schedule of boundaries
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>North</td>
                        <td>:</td>
                        <td>
                          <Row className="row">
                            <Col lg={3}>
                              <img
                                className="img-fluid doc-img shadow border"
                                src={
                                  "http://tsiicscript.autometrybpas.co.in/public/boundry_images/boundry-2022112262820737.jpg"
                                }
                                role="button"
                                onClick={() => showHandler("")}
                              />
                            </Col>
                          </Row>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>South</td>
                        <td>:</td>
                        <td>
                          <Row className="row">
                            <Col lg={3}>
                              <img
                                className="img-fluid doc-img shadow border"
                                src={
                                  "http://tsiicscript.autometrybpas.co.in/public/boundry_images/boundry-2022112262820737.jpg"
                                }
                                role="button"
                                onClick={() => showHandler("")}
                              />
                            </Col>
                          </Row>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>West</td>
                        <td>:</td>
                        <td>
                          <Row className="row">
                            <Col lg={3}>
                              <img
                                className="img-fluid doc-img shadow border"
                                src={
                                  "http://tsiicscript.autometrybpas.co.in/public/boundry_images/boundry-2022112262820737.jpg"
                                }
                                role="button"
                                onClick={() => showHandler("")}
                              />
                            </Col>
                          </Row>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>East</td>
                        <td>:</td>
                        <td>
                          <Row className="row">
                            <Col lg={3}>
                              <img
                                className="img-fluid doc-img shadow border"
                                src={
                                  "http://tsiicscript.autometrybpas.co.in/public/boundry_images/boundry-2022112262820737.jpg"
                                }
                                role="button"
                                onClick={() => showHandler("")}
                              />
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="w-100 justify-content-center">
            {/* <iframe
            src={modalData}
            style={{ height: "500px" }}
            className="w-100"
          ></iframe> */}
            <div className="d-flex w-50">
              <img
                src={modalData}
                className="w-100 text-center mt-2 mb-2"
              ></img>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PlotDetails;
