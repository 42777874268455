import React from "react";
import { Col } from "react-bootstrap";

const BuildingDetails = () => {
  return (
    <div className="bg-light my-2">
      <div className="generallinformation">
        <div className="block block-rounded p-2">
          <div className="block-content block-content-full">
            <div className="row">
              <Col lg={12}>
                <div className="blocks block-rounded tables">
                  <table className="tablelist table-borderless user-det">
                    <thead>
                      <th
                        colspan="3"
                        className="h5 text-light mb-0 font-weight-light p-3"
                      >
                        Details
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>Name</td>
                        <td>:</td>
                        <td>new building</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Use</td>
                        <td>:</td>
                        <td>Commercial</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Sub use</td>
                        <td>:</td>
                        <td>Shop</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Type</td>
                        <td>:</td>
                        <td>Row House</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Type Number of dwelling units
                        </td>
                        <td>:</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Height excluding Stilt / Parking
                        </td>
                        <td>:</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          Height excluding Cellars
                        </td>
                        <td>:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>No. Of Floors</td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr class="bg-purple p-2 rounded my-2">
                        <td
                          colspan="3"
                          class="h5_2 text-light mb-0 font-weight-light p-3"
                        >
                          Floor Details
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Floor Name</td>
                        <td>:</td>
                        <td>Floor 1</td>
                      </tr>{" "}
                      <tr>
                        <td style={{ width: "40%" }}>Height</td>
                        <td>:</td>
                        <td>2</td>
                      </tr>{" "}
                      <tr>
                        <td style={{ width: "40%" }}>Total Floor Area</td>
                        <td>:</td>
                        <td>1</td>
                      </tr>{" "}
                      <tr>
                        <td style={{ width: "40%" }}>Use</td>
                        <td>:</td>
                        <td>Commercial</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingDetails;
