import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Topbar from "../../Topbar";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useLazyUserViewQuery } from "../../../../redux/api/api";
import { useLocation } from "react-router-dom";

const UserView = () => {
  const location = useLocation();
  const [list, setList] = useState({});
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalData2, setModalData2] = useState("");

  // RTK QUERY
  const [userViewApi] = useLazyUserViewQuery();

  const getView = (id) => {
    userViewApi(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getView(location?.state?.data?.id);
    }
  }, []);

  const showHandler = (file) => {
    if (file.match(/\.(pdf)$/)) {
      setModalData(file);
      setModalData2("");
    } else {
      setModalData2(file);
      setModalData("");
    }
    setModal(true);
  };
  return (
    <div>
      <Topbar type={"user"} />
      <div className="bg-light my-2 p-3">
        <div className="generallinformation">
          <div className="block block-rounded p-2">
            <div className="block-content block-content-full">
              <div className="row">
                <Col lg={12}>
                  <div className="blocks block-rounded tables">
                    <table className="tablelist table-borderless user-det">
                      <thead>
                        <th
                          colspan="3"
                          className="h8 mb-0 font-weight-light p-3"
                        >
                          BASIC DETAILS
                        </th>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "40%" }}>Image</td>
                          <td>:</td>
                          <td>
                            <img
                              width={"105px"}
                              src={
                                list?.user_view?.image_path
                                  ? list?.user_view?.image_path
                                  : "http://tsiicscript.abpas.co.in/user_images/user-2022092343602700.jpg"
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>Title</td>
                          <td>:</td>
                          <td>{list?.user_view?.title}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>First Name</td>
                          <td>:</td>
                          <td>{list?.user_view?.first_name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>Middle Name</td>
                          <td>:</td>
                          <td>{list?.user_view?.middle_name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>Last Name</td>
                          <td>:</td>
                          <td>{list?.user_view?.last_name}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>User Name</td>
                          <td>:</td>
                          <td>{list?.user_view?.username}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>E-mail ID</td>
                          <td>:</td>
                          <td>{list?.user_view?.email}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>Mobile Number</td>
                          <td>:</td>
                          <td>{list?.user_view?.mobile}</td>
                        </tr>
                        <tr class="bg-purple p-2 rounded my-2">
                          <td
                            colspan="3"
                            class="h8_2 mb-0 font-weight-light p-3"
                          >
                            ADDRESS DETAILS
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>Address</td>
                          <td>:</td>
                          <td>{list?.user_detail?.address}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>State</td>
                          <td>:</td>
                          <td>{list?.user_detail?.state}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>City</td>
                          <td>:</td>
                          <td>{list?.user_detail?.city}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>Pin Code</td>
                          <td>:</td>
                          <td>{list?.user_detail?.pin_code}</td>
                        </tr>
                        <tr class="bg-purple p-2 rounded my-2">
                          <td
                            colspan="3"
                            class="h8_2 mb-0 font-weight-light p-3"
                          >
                            OTHER DETAILS
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>DOB</td>
                          <td>:</td>
                          <td>{list?.user_detail?.dob}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>Experience</td>
                          <td>:</td>
                          <td>{list?.user_detail?.experience}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>Qualification</td>
                          <td>:</td>
                          <td>{list?.user_detail?.qualification}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>Short Profile</td>
                          <td>:</td>
                          <td>{list?.user_detail?.short_profile}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>Nationality</td>
                          <td>:</td>
                          <td>{list?.user_detail?.nationality}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>Firm Name</td>
                          <td>:</td>
                          <td>{list?.user_detail?.firm_name}</td>
                        </tr>
                        <tr class="bg-purple p-2 rounded my-2">
                          <td
                            colspan="3"
                            class="h8_2 mb-0 font-weight-light p-3"
                          >
                            Proof DETAILS
                          </td>
                        </tr>
                        {/* <tr>
                          <td style={{ width: "40%" }}>License Number</td>
                          <td>:</td>
                          <td>asdasd</td>
                        </tr>
                        <tr>
                          <td style={{ width: "40%" }}>License Validity</td>
                          <td>:</td>
                          <td>2023-01-01</td>
                        </tr> */}
                        <tr>
                          <td style={{ width: "40%" }}>Id Type</td>
                          <td>:</td>
                          <td>
                            {" "}
                            {+list?.user_file?.id_type == 1
                              ? "Pancard"
                              : "Aadhar"}
                          </td>
                        </tr>
                        <Col lg={4}>
                          <p className="mb-1 mx-1 mt-2">
                            <b
                              className="mx-2 fw-bold"
                              style={{ fontSize: "1.0rem" }}
                            >
                              Id File
                            </b>
                          </p>
                          {/* <img
                            style={{ width: "105px" }}
                            className="mt-2"
                            src={
                              "http://tsiicscript.autometrybpas.co.in/public/user_ids/id-20220826120237255.jpg"
                            }
                            role="button"
                            onClick={() => showHandler("id_file")}
                          /> */}
                          <p
                            role={"button"}
                            className="mt-2 mx-2"
                            onClick={() =>
                              showHandler(list?.user_file?.id_file)
                            }
                          >
                            <InsertDriveFileIcon />
                          </p>
                        </Col>
                        <Col lg={4}>
                          <p className="mb-1 mx-1 mt-2">
                            <b
                              className="fw-bold"
                              style={{ fontSize: "1.0rem" }}
                            >
                              Certificate File
                            </b>
                          </p>
                          {/* <img
                            style={{ width: "105px" }}
                            className="mt-2"
                            src={
                              "http://tsiicscript.autometrybpas.co.in/public/user_certificates/certificate-20220826120237285.jpg"
                            }
                            role="button"
                            onClick={() => showHandler("certificate")}
                          /> */}
                          <p
                            role={"button"}
                            className="mt-2 mx-2"
                            onClick={() =>
                              showHandler(list?.user_file?.certificate_file)
                            }
                          >
                            <InsertDriveFileIcon />
                          </p>
                        </Col>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={modal}
          onHide={() => {
            setModal(false);
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="w-100 justify-content-center">
              {modalData !== "" ? (
                <iframe
                  src={modalData}
                  style={{ height: "500px" }}
                  className="w-100"
                ></iframe>
              ) : modalData2 !== "" ? (
                <div className="d-flex w-50">
                  <img
                    src={modalData2}
                    className="w-100 text-center mt-2 mb-2"
                  ></img>
                </div>
              ) : (
                <InsertDriveFileIcon className="w-100 text-center mt-2 mb-2" />
              )}
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default UserView;
