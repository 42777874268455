import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

const ApplicantInformation = () => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();

  const showHandler = (type) => {
    if (type == "id_file") {
      setModalData(
        "http://tsiicscript.autometrybpas.co.in/public/user_ids/id-20220826120237255.jpg"
      );
    } else {
      setModalData(
        "http://tsiicscript.autometrybpas.co.in/public/user_certificates/certificate-20220826120237285.jpg"
      );
    }
    setModal(true);
  };

  return (
    <div className="bg-light my-2">
      <div className="generallinformation">
        <div className="block block-rounded p-2">
          <div className="block-content block-content-full">
            <div className="row">
              <Col lg={12}>
                <div className="blocks block-rounded tables">
                  <table className="tablelist table-borderless user-det">
                    <thead>
                      <th
                        colspan="3"
                        className="h5 text-light mb-0 font-weight-light p-3"
                      >
                        ARCHITECT/ ENGINEER/ SURVEYOR INFORMATION
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>Consultant Type</td>
                        <td>:</td>
                        <td>Architect</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Name</td>
                        <td>:</td>
                        <td>Mr Vijay Vignesh</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Address</td>
                        <td>:</td>
                        <td>Vadapalani</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>License Number </td>
                        <td>:</td>
                        <td>123546792</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Validity</td>
                        <td>:</td>
                        <td>2055-02-12</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Mobile Number</td>
                        <td>:</td>
                        <td>9600309489</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>E-mail ID</td>
                        <td>:</td>
                        <td>vijay.vignesh.ninosit@gmail.com</td>
                      </tr>
                      <tr class="bg-purple p-2 rounded my-2">
                        <td
                          colspan="3"
                          class="h5_2 text-light mb-0 font-weight-light p-3"
                        >
                          Applicant's Information
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Site Number</td>
                        <td>:</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Site Number Type</td>
                        <td>:</td>
                        <td>NA</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Road/Street</td>
                        <td>:</td>
                        <td>2</td>
                      </tr>
                      <tr class="bg-purple p-2 rounded my-2">
                        <td
                          colspan="3"
                          class="h5_2 text-light mb-0 font-weight-light p-3"
                        >
                          Proof Documents
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Id Type</td>
                        <td>:</td>
                        <td>Pancard</td>
                      </tr>
                      <Col lg={4}>
                        <p className="mb-1 mx-1 mt-2">
                          <b
                            className="mx-2 fw-bold"
                            style={{ fontSize: "1.0rem" }}
                          >
                            Id File
                          </b>
                        </p>
                        <img
                          style={{ width: "105px" }}
                          className="mt-2"
                          src={
                            "http://tsiicscript.autometrybpas.co.in/public/user_ids/id-20220826120237255.jpg"
                          }
                          role="button"
                          onClick={() => showHandler("id_file")}
                        />
                      </Col>
                      <Col lg={4}>
                        <p className="mb-1 mx-1 mt-2">
                          <b className="fw-bold" style={{ fontSize: "1.0rem" }}>
                            Certificate File
                          </b>
                        </p>
                        <img
                          style={{ width: "105px" }}
                          className="mt-2"
                          src={
                            "http://tsiicscript.autometrybpas.co.in/public/user_certificates/certificate-20220826120237285.jpg"
                          }
                          role="button"
                          onClick={() => showHandler("certificate")}
                        />
                      </Col>
                    </tbody>
                  </table>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="w-100 justify-content-center">
            {/* <iframe
            src={modalData}
            style={{ height: "500px" }}
            className="w-100"
          ></iframe> */}
            <div className="d-flex w-50">
              <img
                src={modalData}
                className="w-100 text-center mt-2 mb-2"
              ></img>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ApplicantInformation;
