import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAdminAddMutation,
  useAdminEditMutation,
  useLazyAdminViewQuery,
  useLazyRolesQuery,
} from "../../../../redux/api/api";
import Topbar from "../../Topbar";

const AddAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const types = location?.state?.type;
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [rolesApi] = useLazyRolesQuery();
  const [adminAddApi] = useAdminAddMutation();
  const [adminEditApi] = useAdminEditMutation();
  const [adminViewApi] = useLazyAdminViewQuery();

  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [role, setRole] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [nameErr, setNameErr] = useState(false);
  const [userNameErr, setUserNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [cPasswordErr, setCPasswordErr] = useState(false);
  const [roleErr, setRoleErr] = useState(false);

  const getRoles = () => {
    rolesApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let filter = res?.roles?.filter((i) => i?.status == 1);
          // console.log("filter", filter);
          setRoleList(filter);
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getView = () => {
    adminViewApi(location?.state?.data?.id)
      .then((res) => {
        console.log("res", res);
        if (res?.data?.status == "success") {
          let data = res?.data?.admin;
          setName(data?.name);
          setUserName(data?.username);
          setRole(data?.role_id);
          setEmail(data?.email);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  console.log(name, userName, email);

  useEffect(() => {
    getRoles();
    if (types == "edit") {
      getView();
    }
  }, []);

  const submitHandler = () => {
    if (
      name?.length == 0 ||
      userName?.length == 0 ||
      email?.length == 0 ||
      (types !== "edit" && password?.length < 8) ||
      (types !== "edit" && cPassword !== password) ||
      role?.length == 0
    ) {
      setNameErr(true);
      setEmailErr(true);
      setUserNameErr(true);
      setRoleErr(true);
      if (types !== "edit") {
        setPasswordErr(true);
        setCPasswordErr(true);
      }
    } else {
      let formdata = new FormData();

      formdata.append("name", name);
      formdata.append("username", userName);
      formdata.append("email", email);
      formdata.append("role_id", role);
      formdata.append("password", password);
      formdata.append("confirm_password", cPassword);
      setBtn(true);
      if (types == "edit") {
        formdata.append("_method", "PUT");
        adminEditApi({ id: location?.state?.data?.id, payload: formdata })
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              alert(res?.message);
              navigate("/admin");
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      } else {
        adminAddApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              alert(res?.message);
              navigate("/admin");
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  return (
    <div>
      <Topbar type={"admin"} />
      <div className="content">
        <div className="block bg-light block-rounded">
          <div className="block-content user-frm">
            <Row>
              <Col md={6}>
                <div className="form-group position-relative">
                  <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                    Name *
                  </label>
                  <input
                    className="add-admin-frm fs-xs-12 fs-sm-13 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-17"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  {name?.length == 0 && nameErr && (
                    <p className="err">Enter Name</p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group position-relative">
                  <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                    Username *
                  </label>
                  <input
                    className="add-admin-frm fs-xs-12 fs-sm-13 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-17"
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName}
                  />
                  {userName?.length == 0 && userNameErr && (
                    <p className="err">Enter Username</p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group position-relative">
                  <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                    Email *
                  </label>
                  <input
                    className="add-admin-frm fs-xs-12 fs-sm-13 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-17"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  {email?.length == 0 && emailErr && (
                    <p className="err">Enter Email</p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group position-relative">
                  <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                    Role *
                  </label>
                  <select
                    className="add-admin-frm fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-16 fs-xxl-18"
                    onChange={(e) => setRole(e.target.value)}
                    value={role}
                  >
                    <option hidden>Select Role</option>
                    {roleList?.map((item, ind) => {
                      return (
                        <option key={ind} value={item?.id}>
                          {item?.role}
                        </option>
                      );
                    })}
                  </select>
                  {role?.length == 0 && roleErr && (
                    <p className="err">Select Role</p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group position-relative">
                  <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                    Password *
                  </label>
                  <input
                    className="add-admin-frm fs-xs-12 fs-sm-13 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-17"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  {password?.length < 8 && passwordErr && (
                    <p className="err">Enter Password</p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group position-relative">
                  <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                    Confirm Password *
                  </label>
                  <input
                    className="add-admin-frm fs-xs-12 fs-sm-13 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-17"
                    onChange={(e) => setCPassword(e.target.value)}
                    value={cPassword}
                  />
                  {cPassword?.length == 0 && cPasswordErr && (
                    <p className="err">Enter Confirm Password</p>
                  )}
                  {cPassword?.length > 0 && cPassword !== password && (
                    <p className="err">
                      Password Confirm Password Does not match.
                    </p>
                  )}
                </div>
              </Col>

              <div className="form-btn mb-4">
                <button onClick={submitHandler} disabled={btn ? true : false}>
                  Submit
                </button>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
