import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useLazySiteDetailsViewQuery,
  useSiteDetailsAddMutation,
  useSiteDetailsUpdateMutation,
} from "../../../../redux/api/api";
import Topbar from "../../Topbar";

const SiteQuestion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;

  const [question, setQuestion] = useState("");
  const [types, setTypes] = useState("");
  const [questionErr, setQuestionErr] = useState(false);
  const [typesErr, setTypesErr] = useState(false);
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [siteDetailsViewApi] = useLazySiteDetailsViewQuery();
  const [siteDetailsAddApi] = useSiteDetailsAddMutation();
  const [siteDetailsEditApi] = useSiteDetailsUpdateMutation();

  const getView = () => {
    siteDetailsViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let data = res?.site_detail_question;
          setQuestion(data?.question);
          setTypes(data?.type);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (type == "edit") {
      getView();
    }
  }, []);

  const submitHandler = () => {
    if (question?.length == 0 || types?.length == 0) {
      setTypesErr(true);
      setQuestionErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("question", question);
      formdata.append("type", types);
      setBtn(true);
      if (type == "edit") {
        siteDetailsEditApi({
          id: location?.state?.data?.id,
          payload: formdata,
        })
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              alert(res?.message);
              navigate("/site-details-question-list");
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      } else {
        siteDetailsAddApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              alert(res?.message);
              navigate("/site-details-question-list");
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  return (
    <div>
      <Topbar type={`site-details-questions-${type ? type : "add"}`} />
      <div className="p-3">
        <div className="block bg-light block-rounded">
          <div className="block-content">
            <div className="block-content user-frm">
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                      Name *
                    </label>
                    <textarea
                      className="add-admin-frm fs-xs-12 fs-sm-13 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-17"
                      onChange={(e) => setQuestion(e.target.value)}
                      value={question}
                    />
                    {question?.length == 0 && questionErr && (
                      <p className="err2">Enter Name</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                      Type *
                    </label>
                    <select
                      className="add-admin-frm fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-16 fs-xxl-18"
                      onChange={(e) => setTypes(e.target.value)}
                      value={types}
                    >
                      <option hidden>Select Type</option>
                      <option value={1}>Normal</option>
                      <option value={2}>Physical</option>
                    </select>
                    {types?.length == 0 && typesErr && (
                      <p className="err2">Select Tyoe</p>
                    )}
                  </div>
                </Col>
                <div className="form-btn mb-4">
                  <button onClick={submitHandler} disabled={btn ? true : false}>
                    Submit
                  </button>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteQuestion;
