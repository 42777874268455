import React from "react";
import { Col, Row } from "react-bootstrap";

const IntimationFeeLetter = () => {
  return (
    <div>
      <div>
        <div className="generallinformation">
          <div class="block block-rounded">
            <div class="block-content block-content-full">
              <Row>
                <Col lg={12}>
                  <div className="blocks block-rounded">
                    <button className="btn text-black btn-success">
                      Intimation Fee Letter Download
                    </button>
                    <div style={{ marginLeft: "45%" }}>
                      <Col lg={6} className="mb-0 mt-3">
                        <p
                          className="mb-1"
                          style={{
                            lineHeight: "1.6",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          Intimation Fee Letter upload
                        </p>
                        <div class="input-group mb-2 mt-2">
                          <div
                            class="custom-file"
                            style={{
                              border: "1px solid rgb(213 220 225)",
                              borderRadius: "5px",
                            }}
                          >
                            <input
                              type="file"
                              class="custom-file-input"
                              id="inputGroupFile04"
                            />
                          </div>
                        </div>
                      </Col>
                      <div>
                        <button className="btn text-black btn-success">
                          Demand note Issue + Fee intimation
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntimationFeeLetter;
