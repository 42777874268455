import React, { useEffect, useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import Topbar from "../../Topbar";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { FaEdit } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import {
  useLazyPalceViewQuery,
  useLazyPlaceParentListQuery,
  useLazyPlacesQuery,
  usePalceEditMutation,
  usePlaceAddMutation,
} from "../../../../redux/api/api";

const Places = () => {
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [modelDisplay, setModelDisplay] = useState("");
  const [subModel, setSubModel] = useState([]);
  const [activeBtn, setActiveBtn] = useState(null);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [name2, setName2] = useState("");
  const [name2Err, setName2Err] = useState(false);
  const [update, setUpdate] = useState({});
  const [parentData, setParentData] = useState({});
  const [subPlace, setSubPlace] = useState({});

  // RTK QUERY
  const [placeAddApi] = usePlaceAddMutation();
  const [placeEditApi] = usePalceEditMutation();
  const [placePlaceViewApi] = useLazyPalceViewQuery();
  const [placeListApi] = useLazyPlacesQuery();
  const [placeParentListApi] = useLazyPlaceParentListQuery();

  const handleClose = (type, subItem) => {
    setSubPlace(subItem);
    setNameErr(false);
    setName("");
    setShow(!show);
    setModelDisplay(type);
  };

  const handleCloses = (type, data) => {
    setShows(!shows);
    setModelDisplay(type);
    setName2(data?.name);
    setUpdate(data);
  };

  const subModelHandler = (num, id) => {
    let temp = [...subModel];
    let inx = temp.indexOf(num);
    if (temp.includes(num)) {
      temp.splice(inx, temp.length);
      temp.push(num);
    } else {
      temp.push(num);
    }
    setSubModel(temp);
    setActiveBtn(id);
  };

  const getPlaces = () => {
    placeListApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setStateList(res?.place);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getDistirct = (id, num) => {
    placeParentListApi(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          subModelHandler(num, id);
          setParentData((val) => ({ ...val, [num]: res?.place }));
          setDistrictList(res?.sub_place);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const addPalce = () => {
    if (name?.length == 0) {
      setNameErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", name);

      if (subPlace?.id) {
        formdata.append("type", 2);
        formdata.append("parent_id", subPlace?.id);
      } else {
        formdata.append("type", 1);
      }

      placeAddApi(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            if (subPlace?.id) {
              getDistirct(subPlace?.id, 1);
              setSubPlace({});
              setName("");
              setNameErr(false);
              handleClose("");
            } else {
              setName("");
              setNameErr(false);
              handleClose("");
              getPlaces();
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  console.log("update", update);

  const updatePalce = () => {
    if (name2?.length == 0) {
      setName2Err(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", name2);

      if (update?.id) {
        formdata.append("type", 2);
        formdata.append("parent_id", update?.parent_id);
      } else {
        formdata.append("type", 1);
      }

      placeEditApi({ id: update?.id, payload: formdata })
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            if (update?.id) {
              getDistirct(update?.parent_id, 1);
              setSubPlace({});
              setName2("");
              setName2Err(false);
              handleCloses("");
            } else {
              setName2("");
              setName2Err(false);
              handleCloses("");
              getPlaces();
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    getPlaces();
  }, []);

  //   console.log("subModel", subModel);

  return (
    <div>
      <Topbar type={"places"} />
      <div className="p-3">
        <div className="block bg-light block-rounded">
          <div className="block-content">
            <div className="row d-flex flex-wrap p-3 mb-3">
              <Col md={6} sm={6} lg={6} xs={12} className="mb-3">
                <div className="w-100">
                  <div className="w-100">
                    <div class="h5 text-primary font-800 mb-1">State</div>
                    <button
                      className="mb-1 mt-2 border-0 w-100 place-btn"
                      onClick={() => handleClose("Zone")}
                    >
                      + Add State
                    </button>
                  </div>
                  <div className="mb-2 w-100">
                    {stateList?.map((item, ind) => {
                      return (
                        <div
                          className={`w-100 ${
                            activeBtn == item?.id
                              ? "place-list-active"
                              : " place-list"
                          } d-flex justify-content-between align-items-center`}
                        >
                          <p className="font-500">{item?.name}</p>
                          <div className="d-flex place-flot align-items-center">
                            <button
                              className="place-icon p-2"
                              onClick={() => handleCloses("State", item)}
                            >
                              <FaEdit className="d-flex align-items-center justify-content-center mx-1" />
                            </button>
                            <button
                              className="place-icon p-2"
                              onClick={() => getDistirct(item?.id, 1)}
                            >
                              <MdKeyboardArrowRight className="d-flex align-items-center justify-content-center mx-1" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
              {subModel?.includes(1) && (
                <Col md={6} sm={6} lg={6} xs={12} className="mb-3">
                  <div className="w-100">
                    <div class="h5 text-primary font-800 mb-1">District</div>
                    <button
                      className="mb-1 mt-2 border-0 w-100 place-btn"
                      onClick={() => handleClose("District", parentData[1])}
                    >
                      + Add District
                    </button>
                  </div>
                  <div className="mb-2 w-100">
                    {districtList?.map((item, ind) => {
                      return (
                        <div className="w-100 place-list d-flex justify-content-between align-items-center">
                          <p className="font-500">{item?.name}</p>
                          <div className="d-flex place-flot align-items-center">
                            <button
                              className="place-icon p-2"
                              onClick={() => handleCloses("District", item)}
                            >
                              <FaEdit className="d-flex align-items-center justify-content-center mx-1" />
                            </button>
                            {/* <button
                                className="place-icon p-2"
                                onClick={() => subModelHandler(2, null)}
                              >
                                <MdKeyboardArrowRight className="d-flex align-items-center justify-content-center mx-1" />
                              </button> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              )}
              {subModel?.includes(2) && (
                <Col md={6} sm={6} lg={6} xs={12} className="mb-3">
                  <div className="w-100">
                    <div class="h5 text-primary font-800 mb-1">IALA</div>
                    <button
                      className="mb-1 mt-2 border-0 w-100 place-btn"
                      onClick={() => handleClose("IALA")}
                    >
                      + Add IALA
                    </button>
                  </div>
                  <div className="mb-2 w-100">
                    {["Adilabad-1", "Adilabad-2", "Adilabad-3"].map(
                      (item, ind) => {
                        return (
                          <div className="w-100 place-list d-flex justify-content-between align-items-center">
                            <p className="font-500">{item}</p>
                            <div className="d-flex place-flot align-items-center">
                              <button
                                className="place-icon p-2"
                                onClick={() => handleCloses("IALA", item)}
                              >
                                <FaEdit className="d-flex align-items-center justify-content-center mx-1" />
                              </button>
                              <button
                                className="place-icon p-2"
                                onClick={() => subModelHandler(3, null)}
                              >
                                <MdKeyboardArrowRight className="d-flex align-items-center justify-content-center mx-1" />
                              </button>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </Col>
              )}
              {subModel?.includes(3) && (
                <Col md={6} sm={6} lg={6} xs={12} className="mb-3">
                  <div className="w-100">
                    <div class="h5 text-primary font-800 mb-1">
                      Industrial Park
                    </div>
                    <button
                      className="mb-1 mt-2 border-0 w-100 place-btn"
                      onClick={() => handleClose("Industrial Park")}
                    >
                      + Add Industrial Park
                    </button>
                  </div>
                  <div className="mb-2 w-100">
                    {["IP Adilabad-1", "IP Adilabad-2", "IP Adilabad-3"].map(
                      (item, ind) => {
                        return (
                          <div className="w-100 place-list d-flex justify-content-between align-items-center">
                            <p className="font-500">{item}</p>
                            <div className="d-flex place-flot align-items-center">
                              <button
                                className="place-icon p-2"
                                onClick={() =>
                                  handleCloses("Industrial Park", item)
                                }
                              >
                                <FaEdit className="d-flex align-items-center justify-content-center mx-1" />
                              </button>
                              <button
                                className="place-icon p-2"
                                onClick={() => subModelHandler(4, null)}
                              >
                                <MdKeyboardArrowRight className="d-flex align-items-center justify-content-center mx-1" />
                              </button>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </Col>
              )}
              {subModel?.includes(4) && (
                <Col md={6} sm={6} lg={6} xs={12} className="mb-3">
                  <div className="w-100">
                    <div class="h5 text-primary font-800 mb-1">Mandal</div>
                    <button
                      className="mb-1 mt-2 border-0 w-100 place-btn"
                      onClick={() => handleClose("Mandal")}
                    >
                      + Add Mandal
                    </button>
                  </div>
                  <div className="mb-2 w-100">
                    {[
                      "Adilabad (Mavala-New)-1",
                      "Adilabad (Mavala-New)-2",
                      "Adilabad (Mavala-New)-3",
                    ].map((item, ind) => {
                      return (
                        <div className="w-100 place-list d-flex justify-content-between align-items-center">
                          <p className="font-500">{item}</p>
                          <div className="d-flex place-flot align-items-center">
                            <button
                              className="place-icon p-2"
                              onClick={() => handleCloses("Mandal", item)}
                            >
                              <FaEdit className="d-flex align-items-center justify-content-center mx-1" />
                            </button>
                            <button
                              className="place-icon p-2"
                              onClick={() => subModelHandler(5, null)}
                            >
                              <MdKeyboardArrowRight className="d-flex align-items-center justify-content-center mx-1" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              )}
              {subModel?.includes(5) && (
                <Col md={6} sm={6} lg={6} xs={12} className="mb-3">
                  <div className="w-100">
                    <div class="h5 text-primary font-800 mb-1">Village</div>
                    <button
                      className="mb-1 mt-2 border-0 w-100 place-btn"
                      onClick={() => handleClose("Village")}
                    >
                      + Add Village
                    </button>
                  </div>
                  <div className="mb-2 w-100">
                    {[
                      "Adilabad (Mavala-New)-1",
                      "Adilabad (Mavala-New)-2",
                      "Adilabad (Mavala-New)-3",
                    ].map((item, ind) => {
                      return (
                        <div className="w-100 place-list d-flex justify-content-between align-items-center">
                          <p className="font-500">{item}</p>
                          <div className="d-flex place-flot align-items-center">
                            <button
                              className="place-icon p-2"
                              onClick={() => handleCloses("Village", item)}
                            >
                              <FaEdit className="d-flex align-items-center justify-content-center mx-1" />
                            </button>
                            {/* <button
                              className="place-icon p-2"
                              onClick={() => subModelHandler(6)}
                            >
                              <MdKeyboardArrowRight className="d-flex align-items-center justify-content-center mx-1" />
                            </button> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <div className="d-flex w-100 justify-content-between p-4">
          <p className="font-900" style={{ fontSize: "18px" }}>
            {"Add " + modelDisplay}
          </p>
          <button
            className="border-0 bg-transparent"
            onClick={() => handleClose("")}
          >
            <IoClose className="font-900" style={{ fontSize: "20px" }} />
          </button>
        </div>
        <div className="p-3 mb-3 mx-2 w-90">
          <div className="position-relative mb-3">
            <label className="w-100">{modelDisplay} Name</label>
            <input
              className="w-100 p-1  form-control "
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            {name?.length == 0 && nameErr && <p className="err2">Enter Name</p>}
          </div>
          <div className="d-flex justify-content-end gap-2 mt-3">
            <button
              onClick={() => handleClose("")}
              className="btn btn-success btn-sm btn-danger"
            >
              Cancel
            </button>
            <button
              onClick={() => addPalce()}
              className="btn btn-success btn-sm"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>

      <Modal show={shows} onHide={handleCloses}>
        <div className="d-flex w-100 justify-content-between p-4">
          <p className="font-900" style={{ fontSize: "18px" }}>
            {"Edit " + modelDisplay}
          </p>
          <button
            className="border-0 bg-transparent"
            onClick={() => handleCloses("")}
          >
            <IoClose className="font-900" style={{ fontSize: "20px" }} />
          </button>
        </div>
        <div className="p-3 mb-3 mx-2 w-90">
          <div className="position-relative mb-3">
            <label className="w-100">{modelDisplay} Name</label>
            <input
              className="w-100 p-1  form-control"
              onChange={(e) => setName2(e.target.value)}
              value={name2}
            />
            {name2?.length == 0 && name2Err && (
              <p className="err2">Enter Name</p>
            )}
          </div>
          <div className="d-flex justify-content-end gap-2">
            <button
              onClick={() => handleCloses("")}
              className="btn btn-success btn-sm btn-danger"
            >
              Cancel
            </button>
            <button
              onClick={() => updatePalce()}
              className="btn btn-success btn-sm"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Places;
