import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Logo } from "../../../assets";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../../redux/api/api";
import useUser from "../../../redux/store/userData";

const Login = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [btn, setBtn] = useState(false);

  const token = localStorage.getItem("token");

  // RTK QUERY
  const [loginApi] = useLoginMutation();

  const submitHandler = () => {
    if (name?.length == 0 || pass?.length < 8) {
      setNameErr(true);
      setPassErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("username", name);
      formdata.append("password", pass);
      setBtn(true);
      loginApi(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          if (res?.status == "success") {
            localStorage.setItem("token", res?.token);
            localStorage.setItem(
              "permissions",
              JSON.stringify(res?.permissions)
            );
            setUser(res?.admin);
            setTimeout(() => {
              navigate("/dashboard");
              window.location.reload();
            }, 300);
          } else {
            console.log("res", res);
          }
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token]);

  return (
    <div className="main_container">
      <div class="hero-static bg-light">
        <div class="content pt-3">
          <div className="row justify-content-center mt-5">
            <Col md={12} lg={12} xl={6} className="mt-5 p-0">
              <div className="mb-0 mt-5 p-0 bg-form rounded shadow">
                <div className="card_login">
                  <div className="col-lg-6 col-sm-12">
                    <div className="py-5 px-2">
                      <div className="text-center">
                        <img
                          src={Logo}
                          className="img-fluid text-center my-1 bg-white rounded p-1"
                          alt=""
                        />
                      </div>

                      <h3 className="login_h5 mt-4 text-white text-uppercase text-center">
                        Autometry Building Plan Approval System
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="p-sm-3 px-lg-4 py-lg-3 bg-white">
                      <h4 className="text-dark font-weight-bolder my-2">
                        Welcome, please login.
                      </h4>

                      <div>
                        <div className="py-3">
                          <div className="form-groups position-relative">
                            <input
                              className="form-control-alts form-controls form-control-lgs"
                              id="username"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                              name="username"
                              placeholder="Username"
                            ></input>
                            {name?.length == 0 && nameErr && (
                              <p className="err">Enter Username</p>
                            )}
                          </div>
                          <div className="form-group position-relative">
                            <input
                              type="password"
                              className="form-control-alts form-controls form-control-lgs"
                              id="password"
                              name="password"
                              onChange={(e) => setPass(e.target.value)}
                              value={pass}
                              placeholder="Password"
                            ></input>
                            {pass?.length < 8 && passErr && (
                              <p className="err">Enter Password</p>
                            )}
                          </div>
                          {/* <div style="color: red">
                       {{ loginErrors }}
                      </div> */}
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                          <div className="row form-group text-center">
                            <Col className="mt-4">
                              <button
                                className="btn btn-alt-light btn-block d-flex gap-2"
                                onClick={() => submitHandler()}
                                disabled={btn ? true : false}
                              >
                                <LoginIcon />
                                Sign In
                              </button>
                            </Col>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
