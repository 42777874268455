import React from "react";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/sass/App.scss";
import "./assets/sass/Responsive.scss";
import "./assets/sass/style.scss";
import Home from "./screens/user/Home";
import NavBar from "./screens/user/NavBar/NavBar";
import Layout from "./screens/user/Layout/Layout.";
import LayoutStaff from "./screens/staff/Layout/LayoutStaff";
import Login from "./screens/user/auth/Login";
import StaffLogin from "./screens/staff/auth/StaffLogin";
import Admins from "./screens/user/components/admin/Admins";
import StaffDashboard from "./screens/staff/components/StaffDashboard";
import ApplicationList from "./screens/staff/components/ApplicationList";
import Roles from "./screens/user/components/role/Roles";
import Users from "./screens/user/components/user/Users";
import UserApplication from "./screens/user/components/user/UserApplication";
import AddAdmin from "./screens/user/components/admin/AddAdmin";
import AddRole from "./screens/user/components/role/AddRole";
import UserView from "./screens/user/components/user/UserView";
import Places from "./screens/user/components/screens/Places";
import BuildingDetails from "./screens/user/components/screens/BuildingDetails";
import SiteQuestions from "./screens/user/components/site-qustions/SiteQuestions";
import SiteQuestion from "./screens/user/components/site-qustions/SiteQuestion";
import ApplicationDocuments from "./screens/user/components/application-document/ApplicationDocuments";
import ApplicationDocument from "./screens/user/components/application-document/ApplicationDocument";
import ApplicationCheckLists from "./screens/user/components/application-check/ApplicationCheckLists";
import ApplicationCheckList from "./screens/user/components/application-check/ApplicationCheckList";
import ProposedActivity from "./screens/user/components/proposed-activity/ProposedActivity";
import ProposedActivityLists from "./screens/user/components/proposed-activity/ProposedActivityLists";
import PrivateRoutes from "./redux/store/privateRoutes";
import UserPassword from "./screens/user/components/user/UserPassword";
import QueueList from "./screens/user/components/queueList/QueueList";

const ReactRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/staff/signin" element={<StaffLogin />} />
      <Route element={<PrivateRoutes />}>
        <Route element={<Layout />}>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/admin" element={<Admins />} />
          <Route path="/admin-add" element={<AddAdmin />} />
          <Route path="/roles" element={<Roles />} />
          <Route path="/role-add" element={<AddRole />} />
          <Route path="/users" element={<Users />} />
          <Route path="/user-password-change" element={<UserPassword />} />
          <Route path="/user-view" element={<UserView />} />
          <Route path="/users-application" element={<UserApplication />} />
          <Route path="/places" element={<Places />} />
          <Route path="/building-details" element={<BuildingDetails />} />
          <Route
            path="/site-details-question-list"
            element={<SiteQuestions />}
          />
          <Route path="/site-details-question" element={<SiteQuestion />} />
          <Route
            path="/application-document-list"
            element={<ApplicationDocuments />}
          />
          <Route
            path="/application-document"
            element={<ApplicationDocument />}
          />
          <Route
            path="/application-checklist-list"
            element={<ApplicationCheckLists />}
          />
          <Route
            path="/application-checklist"
            element={<ApplicationCheckList />}
          />
          <Route
            path="/proposed-activity-list"
            element={<ProposedActivityLists />}
          />
          <Route path="/proposed-activity" element={<ProposedActivity />} />
          <Route path="/queue-list" element={<QueueList />} />
        </Route>
      </Route>

      <Route element={<LayoutStaff />}>
        <Route path="/staff/dashboard" element={<StaffDashboard />} />
        <Route path="/staff/application-list" element={<ApplicationList />} />
      </Route>
    </Routes>
  );
};

export default ReactRoutes;
