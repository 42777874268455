import React from "react";
import { Outlet } from "react-router-dom";
import NavBarTopStaff from "../NavBar/NavBarTopStaff";

const LayoutStaff = () => {
  return (
    <div className="bg-lightBlue d-md-flex min-h-100">
      <div className="top-design-staff position-fixed z-3">
        <NavBarTopStaff />
      </div>
      <div className="body-design w-100">
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutStaff;
