import React from "react";
import { Col } from "react-bootstrap";

const ApplicantCheckList = () => {
  return (
    <div className="bg-light my-2">
      <div className="generallinformation">
        <div className="block block-rounded p-2">
          <div className="block-content block-content-full">
            <div className="row">
              <Col lg={12}>
                <div className="blocks block-rounded tables">
                  <table className="tablelist table-borderless user-det">
                    <thead>
                      <th
                        colspan="3"
                        className="h5 text-light mb-0 font-weight-light p-3"
                      >
                        DETAILS
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>Description</td>
                        <td>:</td>
                        <td>appliation checklist1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Value</td>
                        <td>:</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Remarks</td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Description</td>
                        <td>:</td>
                        <td>Is site part of LRS approved?</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Value</td>
                        <td>:</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Remarks</td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Description</td>
                        <td>:</td>
                        <td>Is site part of approved layout?</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Value</td>
                        <td>:</td>
                        <td> Yes</td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>Remarks</td>
                        <td>:</td>
                        <td>1</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantCheckList;
