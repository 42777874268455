import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useApplicationCheckAddMutation,
  useApplicationCheckUpdateMutation,
  useLazyApplicationCheckViewQuery,
} from "../../../../redux/api/api";
import Topbar from "../../Topbar";

const ApplicationCheckList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const [btn, setBtn] = useState(false);

  const [description, setDescription] = useState("");
  const [info, setInfo] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [infoErr, setInfoErr] = useState("");

  // RTK QUERY
  const [applicationCheckAddApi] = useApplicationCheckAddMutation();
  const [applicationCheckUpdateApi] = useApplicationCheckUpdateMutation();
  const [applicationCheckViewApi] = useLazyApplicationCheckViewQuery();

  const getView = () => {
    applicationCheckViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let data = res?.place;
          setDescription(data?.description);
          setInfo(data?.info);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (type == "edit") {
      getView();
    }
  }, []);

  const submitHandler = () => {
    if (description?.length == 0 || info?.length == 0) {
      setInfoErr(true);
      setDescriptionErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("description", description);
      formdata.append("info", info);
      setBtn(true);
      if (type == "edit") {
        applicationCheckUpdateApi({
          id: location?.state?.data?.id,
          payload: formdata,
        })
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              alert(res?.message);
              navigate("/application-checklist-list");
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      } else {
        applicationCheckAddApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              alert(res?.message);
              navigate("/application-checklist-list");
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  return (
    <div>
      <Topbar type={`application-checklist-${type ? type : "add"}`} />
      <div className="p-3">
        <div className="block bg-light block-rounded">
          <div className="block-content">
            <div className="block-content user-frm">
              <Row>
                <Col md={6}>
                  <div className="form-group position-relative">
                    <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                      Description *
                    </label>
                    <input
                      className="add-admin-frm fs-xs-12 fs-sm-13 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-17"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                    {description?.length == 0 && descriptionErr && (
                      <p className="err">Enter Description</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group position-relative">
                    <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                      Information *
                    </label>
                    <textarea
                      className="add-admin-frm fs-xs-12 fs-sm-13 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-17"
                      onChange={(e) => setInfo(e.target.value)}
                      value={info}
                    />
                    {info?.length == 0 && infoErr && (
                      <p className="err">Enter Information</p>
                    )}
                  </div>
                </Col>
                <div className="form-btn mb-4">
                  <button onClick={submitHandler} disabled={btn ? true : false}>
                    Submit
                  </button>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationCheckList;
