import React, { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { GlobalFilter } from "../staff/components/dashboards/GlobalFilter";
import { Link } from "react-router-dom";

function Table({ columns, data, type, path }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show">
        <Col className="d-flex justify-content-end" md={12} lg={12} sm={12}>
          <Link to={path}>
            <Button
              style={{ background: "rgb(95,188,95)" }}
              className="btn-sm btn-round has-ripple ml-2 border-0 text-white"
            >
              <i className="feather icon-plus" /> + Add{" "}
              {path == "/site-details-question"
                ? "Site Details Question"
                : path == "/application-document"
                ? "Application Document"
                : path == "/application-checklist"
                ? "Application Checklist"
                : path == "/proposed-activity"
                ? "Proposed Activity"
                : null}
            </Button>
          </Link>
        </Col>
        {type !== "no-search" && (
          <Col className="d-flex justify-content-start">
            <div>
              <label className="font-600 mb-2">Search</label>
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
          </Col>
        )}
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="text-center  mb-2 py-3"
            >
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="text-center  mb-2 py-3"
                  style={{ fontFamily: "f1", fontSize: "14px" }}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="text-center  py-2">
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="text-center py-2">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

export default Table;
