import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useApplicationDocumentAddMutation,
  useApplicationDocumentUpdateMutation,
  useLazyApplicationDocumentViewQuery,
} from "../../../../redux/api/api";
import Topbar from "../../Topbar";

const ApplicationDocument = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;

  const [types, settypes] = useState("");
  const [name, setName] = useState("");
  const [typesErr, settypesErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [applicationDocAddApi] = useApplicationDocumentAddMutation();
  const [applicationDocUpdateApi] = useApplicationDocumentUpdateMutation();
  const [applicationDocViewApi] = useLazyApplicationDocumentViewQuery();

  const getView = () => {
    applicationDocViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let data = res?.application_document;
          setName(data?.name);
          settypes(data?.type);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (type == "edit") {
      getView();
    }
  }, []);

  const submitHandler = () => {
    if (name?.length == 0 || types?.length == 0) {
      settypesErr(true);
      setNameErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("name", name);
      formdata.append("type", types);
      setBtn(true);
      if (type == "edit") {
        applicationDocUpdateApi({
          id: location?.state?.data?.id,
          payload: formdata,
        })
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              alert(res?.message);
              navigate("/application-document-list");
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      } else {
        applicationDocAddApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              alert(res?.message);
              navigate("/application-document-list");
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  return (
    <div>
      <Topbar type={`application-documents-${type ? type : "add"}`} />
      <div className="p-3">
        <div className="block bg-light block-rounded">
          <div className="block-content">
            <div className="block-content user-frm">
              <Row>
                <Col md={6}>
                  <div className="form-group position-relative">
                    <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                      Name *
                    </label>
                    <input
                      className="add-admin-frm fs-xs-12 fs-sm-13 fs-md-14 fs-lg-14 fs-xl-15 fs-xxl-17"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    {name?.length == 0 && nameErr && (
                      <p className="err">Enter Name</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group position-relative">
                    <label className="fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20">
                      Type *
                    </label>
                    <select
                      className="add-admin-frm fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-16 fs-xxl-18"
                      onChange={(e) => settypes(e.target.value)}
                      value={types}
                    >
                      <option hidden>Select Type</option>
                      <option value="1">Primary</option>
                      <option value="2">Additional</option>
                      <option value="3">MSB Hr</option>
                    </select>
                    {types?.length == 0 && typesErr && (
                      <p className="err">Select Type</p>
                    )}
                  </div>
                </Col>
                <div className="form-btn mb-4">
                  <button onClick={submitHandler} disabled={btn ? true : false}>
                    Submit
                  </button>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationDocument;
